import React from 'react';
import { Box,  Card, Link, CardMedia, CardContent, Stack, ButtonGroup, Button, Typography, Grid } from '@mui/material';
import { getNftData, getContractData } from './supaBaseFuncs';
import VerifiedIcon from '@mui/icons-material/Verified';
import ContractCard from './ContractCard';
import { ethers } from 'ethers';
import { convertBlocksToTime } from './Etherfuncs.js';
import { blackScholes, bsIVOL } from './blackScholes.js';

const GreeksCard = ({ theme, mode, contractAddress, price, isCall, strike, expiry, block, showColor=true}) => {

const [contractData, setContractData] = React.useState(null);


React.useEffect(()=>{
    async function handleContractData(){
        try {
            const cd = await getContractData(contractAddress);
            setContractData(cd[0]);
        } catch (error) {
            console.error('Error fetching contract data:', error);
        } 
    }
    handleContractData();
    }, [contractAddress]);

const floorPrice = 0.12; //contractData?.floorPrice;

let ivol = 0;
  if (Number(expiry) <= block){
    ivol = 0;
  } else {
    ivol = bsIVOL(floorPrice, strike, expiry-block, price, isCall);
  }

  //if not ivol='-' round ivol to integer
  if (ivol !== '-'){
    ivol = Math.round(ivol);
  }
  
  return (
    <Card sx={{height:'15px', width:'100%', borderRadius:0, padding:0, paddingLeft:0, margin:0, mb:'2px', boxShadow:0, background:'transparent'
    }}>
      <Stack
        noWrap
        direction="row"
        spacing={1}
        sx={{
            justifyContent: "flex-start",
            alignItems: "space-between",
        }}
        >
          <Card sx={{mr:'2px',height:'20px', width:'5px', borderRadius:'2px', boxShadow:1, background: showColor ? (isCall? '#264a39':'#f44336'):'transparent'}}>
                <Typography sx={{fontSize:10,  textOrientation: 'sideways', writingMode: 'vertical-rl'}}>
                    {'  '}
                </Typography>
          </Card>
          <Stack noWrap direction="column">
                   
            {Number(expiry) > block ?
             <Typography sx={{fontSize:8, display:'flex', justifyContent:'left', alignItems:'center'}}>
             σ {String(ivol)+'%'}
           </Typography>
           :
            <Typography sx={{fontSize:8, display:'flex', justifyContent:'left', alignItems:'center'}}>
              {' '}
            </Typography>
            }
            
          </Stack>
       </Stack>
    </Card>
    );
};

export default GreeksCard;