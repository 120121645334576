import * as React from 'react';
import {Box, IconButton,FormGroup, FormControlLabel, Tooltip, Checkbox, Link,  Collapse, Card, CardMedia, CardContent, Stack, Divider, Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Typography, ButtonGroup} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {SelectNFTmodal} from './SelectNFTModal';
import { ethers } from 'ethers'
//import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json'
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json'
import {Logo} from './ethlogo.js';
import InputAdornment from '@mui/material/InputAdornment';
import IOSSwitch from './IOSwitch';
import {ContractModal} from './ContractModal';
import UploadFile from './IPFSupload';
import LinearProgress from './LinearProgress';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useState, useEffect, useMemo, useCallback, memo } from 'react';
import ESlogo from './logos/etherscan-logo-light-circle.svg';
import AnimatedOrderCard from './AnimatedOrderCard';
import FormattedBlockNumber from './FormattedBlockNumber';
import Snackbar from '@mui/material/Snackbar';
import SearchableContractInput from './contractSelectDropdown.js';

import X_logo from './logos/X_logo.svg';
import X_logoWhite from './logos/XlogoWhite.svg'
import VerifiedIcon from '@mui/icons-material/Verified';
import LanguageIcon from '@mui/icons-material/Language';
import OSwhite from './logos/OSwhite.svg' ;
import OSblack from './logos/OSblack.svg';
import ME from './logos/ME.svg' ;
import MEDark from './logos/MEDark.svg';
import DiscordWhite from './logos/discordWhite.svg';
import DiscordBlack from './logos/discordBlack.svg';
import Blur from './logos/Blur.svg';
import BlurDark from './logos/BlurDark.svg';
import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';
import ModalCreateOrder from './ModalCreateOrder';
import {createOrder, acceptOrder, getOrder} from './ethCreateOffer.js';
import { getOrders, getBlockGas, supabase,getUniqueUnderlyingFromOrders, getNftData, getContractData } from './supaBaseFuncs.js';
//import Moralis from 'moralis';
//import FormattedPrice from './FormattedPrice.js';
//import OrderCard from './OrderCard.js';
import {convertBlocksToTime} from './Etherfuncs.js';

import { NFTDataProvider } from './NFTDataContext';

import { useData } from './contexts/DataContext';


const INFURA_URL = process.env.REACT_APP_INFURA_URL;

// Create a memoized version of AnimatedOrderCard
const MemoizedAnimatedOrderCard = memo(AnimatedOrderCard, (prevProps, nextProps) => {
  // Only re-render if these props change
  return (
    prevProps.order?.orderId === nextProps.order?.orderId &&
    prevProps.curr === nextProps.curr &&
    prevProps.userAccount === nextProps.userAccount &&
    prevProps.mode === nextProps.mode &&
    prevProps.openModalId === nextProps.openModalId && // Add this line
    prevProps.strike === nextProps.strike &&  // Add this line
    prevProps.expiry === nextProps.expiry     // Add this line
  );
});

export function OrderViewContract(props) {
  const { theme, mode, switchPC, userAccount, curr, toggleTabBar, blockMapping, wallet } = props;

  // Get everything we need from DataContext
  const {
    filteredOrders,
    expiries,
    organizedOrders,
    bestOrders,
    filterContract,
    setFilterContract,
    uniqueContracts,
    refreshData,  // Add this to DataContext if not already present
    initialized
  } = useData();

  // Local state
  const [batchState, setBatchState] = useState({
    myOrders: [],
    uniqueContracts: []
  });
  const [expsOpen, setExpsOpen] = useState({});
  const [hideEmptyExpiries, setHideEmptyExpiries] = useState(true);
  const [modalSelectNFTOrderId, setModalSelectNFTOrderId] = useState(null);
  const [modalSelectedNFT, setModalSelectedNFT] = useState({});
  const [openModalId, setOpenModalId] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [moralisData, setMoralisData] = useState({/* ... */});

  // Replace GetOrders with a call to context's refresh function
  useEffect(() => {
    if (curr > 0 && !initialLoadComplete) {
      refreshData(filterContract);
      setInitialLoadComplete(true);
    }
  }, [curr, initialLoadComplete, filterContract, refreshData]);

  // Update contract metadata when filter changes
  useEffect(() => {
    if (ethers.isAddress(filterContract)) {
      getNFTContractMetadata(filterContract);
    }
  }, [filterContract]);

  // Subscription effect
  useEffect(() => {
    if (!initialLoadComplete) return;

    const ordersChannel = supabase.channel('tradeView-orders-channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'orders' }, () => {
        refreshData(filterContract);
      })
      .subscribe();

    const optionsChannel = supabase.channel('tradeView-options-channel')
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'options' }, () => {
        refreshData(filterContract);
      })
      .subscribe();

    return () => {
      ordersChannel.unsubscribe();
      optionsChannel.unsubscribe();
    };
  }, [initialLoadComplete, filterContract, refreshData]);

  // Add this function back to the component
  async function getNFTContractMetadata(address) {
    try {
        // Use the existing getContractData function
        const contractData = await getContractData(address.toLowerCase());
        
        if (!contractData || contractData.length === 0) {
            console.error('No contract data found');
            return;
        }

        let moD = contractData[0]; // getContractData returns an array



        // If no collection logo, try to get it from NFT metadata
        if (!moD.collection_logo) {
            const nftData = await getNftData(address.toLowerCase(), '1');
            
            if (nftData && nftData.length > 0) {
                moD = {
                    ...moD,
                    'collection_logo': nftData[0].normalized_metadata?.image
                };
                console.log("NFT Contract Metadata with collection_logo:", nftData[0].normalized_metadata?.image);
            }
        }

        console.log("NFT Contract Metadata:", moD);
        setMoralisData(moD);
    } catch (e) {
        console.error('Error in getNFTContractMetadata:', e);
    }
}

// Add these functions after getNFTContractMetadata
const hasStrikes = (exp) => {
  if (!bestOrders || !bestOrders[exp]) return false;
  const expData = bestOrders[exp];
  const callsLength = Object.keys(expData?.calls || {}).length;
  const putsLength = Object.keys(expData?.puts || {}).length;
  return callsLength + putsLength > 0;
};

// Function to handle expanding/collapsing individual expiries
function handleExps(exp) {
  setExpsOpen(prevState => ({
    ...prevState,
    [exp]: !prevState[exp]
  }));
}

// Function to handle expand/collapse all
function handleExpandAll(expand) {
  setExpsOpen(prevState => {
    const newState = {};
    Object.keys(organizedOrders || {}).forEach(exp => {
      newState[exp] = expand;
    });
    return newState;
  });
}

// Add this with the other handler functions
const handleCloseOrderCard = useCallback(() => {
  setOpenModalId(null);
  setModalSelectNFTOrderId(null);
}, []);

// Add the return statement with the JSX
return (
  <Card sx={{maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, minWidth: {sm:'97%', sm:'90%', lg:'50%'}, paddingLeft:1, paddingRight:0}}>
    <CardContent sx={{paddingLeft:0, paddingRight:0, marginLeft:1, marginRight:0}}>    
      <Grid container spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
        alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>              
        <Grid item xs={12} style={{ paddingTop: 10 }}>
          <Stack direction='column'>
            <Typography variant="h6" align='left' style={{paddingBottom:0, paddingTop:10}}>
              Trade Options
            </Typography>
            
            <div>
              <NFTDataProvider>
                <SearchableContractInput
                  contracts={uniqueContracts}
                  onChange={setFilterContract}
                  label="Underlying Contract"
                  value={filterContract}
                  defaultValue={filterContract}
                />
              </NFTDataProvider>
            </div>
          </Stack>
        </Grid>

        {/* NFT Collection Card */}
        <Grid container item xs={12} style={{ paddingTop: 10 }} 
          alignItems="stretch" columns={{ xs: 6 }}>
          <Grid item xs={6}>
            <Card sx={{ 
              position: 'relative', 
              overflow: 'hidden',
              marginRight: 2
            }}>
              {/* Banner Image */}
              <div style={{
                backgroundImage: `url(${moralisData.collection_banner_image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '400px',
                filter: 'grayscale(100%)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1
              }}/>
              
              {/* Overlay */}
              <div style={{
                backgroundColor: mode === 'light' ? 
                  'rgba(255, 255, 255, 0.8)' :
                  'rgba(0, 0, 0, 0.8)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 2
              }}/>

              {/* Content */}
              <CardContent sx={{ position: 'relative', zIndex: 3 }}> 
                {/* Collection Title */}
                <Typography variant='body2' align='center' color={mode === 'light' ? 'primary' : 'primary.light'}>
                  Underlying collection
                </Typography>
                
                {/* Collection Name with Verification */}
                <Link 
                  href={`https://sepolia.etherscan.io/address/${moralisData.token_address}`} 
                  target="_blank" 
                  underline="hover" 
                  color={mode=='light'?'black':'white'}
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Typography variant='h6' style={{ fontSize: 18 }}>
                    {moralisData.name}
                    {moralisData.verified_collection && (
                      <VerifiedIcon style={{ width: '20px', height: '20px', marginLeft:'2px', verticalAlign: 'middle' }} />
                    )}
                  </Typography>
                </Link>

                {/* Social Links */}
                <Grid container alignContent='center' justifyContent='center' spacing={2} sx={{ width: '100%' }}>
                            <Grid item>
                                <Link href={`https://opensea.io/assets/ethereum/${moralisData.token_address}`} target="_blank" rel="noopener noreferrer">
                                    <img src={mode === 'light' ? OSblack : OSwhite} alt="OpenSea" style={{ width: '16px', height: '16px' }} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={`https://blur.io/eth/collection/${moralisData.token_address}`} target="_blank" rel="noopener noreferrer">
                                    <img src={mode === 'dark' ? Blur : BlurDark} alt="Blur" style={{ width: '16px', height: '16px' }} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={`https://magiceden.io/collections/ethereum/${moralisData.token_address}`} target="_blank" rel="noopener noreferrer">
                                    <img src={mode === 'dark' ? ME : MEDark} alt="Magic Eden" style={{ width: '16px', height: '16px' }} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={moralisData.project_url} target="_blank" rel="noopener noreferrer">
                                <LanguageIcon style={{ color: mode === 'light' ? 'black' : 'white', width: '20px', height: '20px', marginLeft: '5px', verticalAlign: 'top' }} />
                                </Link>
                            </Grid>
                            <Grid item sx={{alignContent:'top'}}>
                                <Link href={`https://twitter.com/${moralisData.twitter_username}`} target="_blank" rel="noopener noreferrer" sx={{height:'12px', alignContent:'top', paddingTop:0, marginTop:0}}>
                                    <img src={mode === 'light' ? X_logo : X_logoWhite} alt="X" style={{ width: '14px', height: '14px', marginRight: '5px' }} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={moralisData.discord_url} target="_blank" rel="noopener noreferrer">
                                    <img src={mode === 'light' ? DiscordBlack : DiscordWhite} alt="Discord" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                                </Link>
                            </Grid>
                        </Grid>
                        <Typography 
                            style={{ 
                                fontSize: 12, 
                                display: 'inline-flex', // Changed to inline-flex
                                alignItems: 'center',
                                gap: 1
                            }}
                            sx={{maxWidth:'80%'}}
                            color={mode === 'light' ? 'primary' : 'primary.light'}
                        >
                            <Link 
                                href={`https://sepolia.etherscan.io/address/${moralisData.token_address}`} 
                                target="_blank" 
                                underline="hover" 
                                rel="noopener noreferrer"
                                color={mode === 'light' ? 'primary' : 'primary.light'}
                                style={{ 
                                    display: 'inline-block', // Added display inline-block
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    verticalAlign: 'middle' // Added vertical align
                                }} 
                            >
                                {String(moralisData.token_address).slice(0, 6) + "..." + String(moralisData.token_address).slice(-4)}
                            </Link>
                            <Tooltip title="Copy to clipboard">
                                <IconButton 
                                    size="small" 
                                    onClick={() => {
                                        navigator.clipboard.writeText(moralisData.token_address)
                                            .then(() => setCopySuccess(true))
                                            .catch(err => console.error('Could not copy text: ', err));
                                    }}
                                    sx={{ 
                                        p: 1.5,
                                        verticalAlign: 'middle', // Added vertical align
                                        marginLeft: '-4px' // Negative margin to bring it closer
                                    }}
                                    aria-label="copy address"
                                >
                                    <ContentCopyIcon sx={{ fontSize: '0.8rem' }}/>
                                </IconButton>
                            </Tooltip>
                            <Snackbar
                                open={copySuccess}
                                autoHideDuration={2000}
                                onClose={() => setCopySuccess(false)}
                                message="Address copied!"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            />
                        </Typography>
                        <Box display='flex' justifyContent='center' alignItems='center'>
                        <CardMedia component="img" src={moralisData.collection_logo}  height='170px'  sx={{ borderRadius: '100px', boxShadow: 0, maxWidth:'25%', minWidth:'170px' }}/>
                        
                        </Box>

                {/* Floor Price */}
                <Box justifyContent='center' alignItems='center' sx={{minWidth:'70%'}}>
                  <Stack direction='row' justifyContent='center' alignItems='center'>
                    <IconButton onClick={() => window.open('https://goerli.etherscan.io/address/0x7002cC4e2b8b539E71c9BcEe8529c6AED17B8E96')}>
                      <img src={OSwhite} width={15}/>
                    </IconButton>
                    <Typography align='center'>
                      Ξ {moralisData.floor_price} 
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {/* Orders Section */}
      <Box sx={{
        width:'100%', 
        overflowX:'scroll',
        marginTop:2, 
        paddingBottom:3,
        marginLeft:'-16px', 
        paddingTop:'30px', 
        borderTop:1, 
        borderBottom:1, 
        borderColor:'#232526',
        scrollbarColor: '#282929 #6f7070',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#6f7070',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#282929',
          borderRadius: '4px',
        }
      }}>
        {/* Orders Grid */}
        <Grid container spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
          alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>              
          {/* Controls */}
          <Grid item xs={12} style={{ padding: 1, paddingLeft:35, margin:1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px' }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleExpandAll(!Object.values(expsOpen).some(v => v))}
              startIcon={Object.values(expsOpen).some(v => v) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              style={{padding:3, margin:0}}
              sx={{color: mode === 'light' ? 'primary' : 'white', borderColor: mode === 'light' ? 'primary' : 'white'}}
            >
              <Typography fontSize={10} sx={{color: mode === 'light' ? 'primary' : 'white'}}>
                {Object.values(expsOpen).some(v => v) ? 'Collapse All' : 'Expand All'}
              </Typography>
            </Button>
            
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideEmptyExpiries}
                  onChange={(e) => setHideEmptyExpiries(e.target.checked)}
                  size="small"
                />
              }
              label={<Typography fontSize={12}>Hide empty expiries</Typography>}
            />
          </Grid>

          {/* Headers */}
          <Grid container item xs={12} style={{ paddingTop: 0, marginTop:-10, minWidth:'660px' }} 
            alignItems="stretch" columns={{ xs: 9}} wrap='nowrap'>
            {/* ... Headers for Calls and Puts ... */}
          </Grid>

          {/* Expiry Sections */}
          {Object.keys(organizedOrders || {})
            .filter(exp => !hideEmptyExpiries || hasStrikes(exp))
            .map((exp, index) => {
              const strikeCount = Object.keys(bestOrders?.[exp]?.calls || {}).length;
              
              return (
                <Grid container item xs={12} key={`expiry-${exp}`}
                  style={{ paddingTop: 10, minWidth:'660px' }}  
                  alignItems="stretch" columns={{ xs: 9}} wrap='nowrap'>
                  
                  {/* Calls Section */}
                  <Grid container item xs={4} columns={{xs: 2}} alignItems="stretch" sx={{minWidth:'50%'}}>
                    <Grid item xs={2}>
                      <Divider/>
                      <Divider textAlign="left">
                        <Stack direction="row" sx={{display:'flex', alignItems:'center'}}>
                          {strikeCount > 0 ? (
                            <IconButton onClick={() => handleExps(exp)}>
                              {expsOpen[exp] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                          ) : (
                            <div style={{ width: '40px', height: '40px' }} />
                          )}
                          
                          <Typography fontSize={12}>
                            Expiry at block #{exp} {' '}
                            <Typography component="span" color="text.secondary" fontSize={12}>
                              ({convertBlocksToTime(exp-curr)})
                            </Typography>
                            <Typography component="span" color={mode === 'light' ? 'primary' : 'primary.light'} fontSize={12}>
                              {' '}({strikeCount} {strikeCount === 1 ? 'strike' : 'strikes'})
                            </Typography>
                          </Typography>
                        </Stack>
                      </Divider>
                    </Grid>

                    {expsOpen[exp] && (
                      <div style={{minWidth:'100%', margin:0, padding:0}}>
                        {Object.entries(bestOrders?.[exp]?.calls || {}).map(([strike, e], index) => (
                          <Grid container item style={{width: '100%', margin: 0, padding: 0}} key={`call-${exp}-${strike}`}>
                            <Grid container style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                              {/* Strike label/value column */}
                              <Grid item style={{width: '10%', flexShrink: 0}}>
                                {index === 0 && (
                                  <Typography size="small" style={{fontSize: 10, textAlign: 'left'}}>
                                    Strike
                                  </Typography>
                                )}
                                <Typography size="small" style={{fontSize: 10, textAlign: 'left'}}>
                                  {strike}
                                </Typography>
                              </Grid>
                              
                              {/* Bid card */}
                              <Grid item style={{width: '45%', flexGrow: 1}}>
                                <MemoizedAnimatedOrderCard 
                                  theme={theme} 
                                  order={e.bestBid} 
                                  isBid={true}  
                                  userAccount={userAccount} 
                                  curr={curr} 
                                  option={e} 
                                  openModalId={openModalId}
                                  setOpenModalId={setOpenModalId}
                                  handleClose={handleCloseOrderCard}
                                  contractAddress={filterContract}
                                  strike={strike}
                                  expiry={exp}
                                  isCall={true}
                                  modalSelectNFTVisible={modalSelectNFTOrderId}
                                  setModalSelectNFTVisible={setModalSelectNFTOrderId}
                                  modalSelectedNFT={modalSelectedNFT}
                                  setModalSelectedNFT={setModalSelectedNFT}
                                  mode={mode}
                                  blockMapping={blockMapping}
                                  toggleTabBar={toggleTabBar}
                                  wallet={wallet}
                                />
                              </Grid>

                              {/* Offer card */}
                              <Grid item style={{width: '45%', flexGrow: 1}}>
                                <MemoizedAnimatedOrderCard 
                                  theme={theme} 
                                  order={e.bestOffer} 
                                  isBid={false}  
                                  userAccount={userAccount} 
                                  curr={curr}  
                                  option={e} 
                                  openModalId={openModalId}
                                  setOpenModalId={setOpenModalId}
                                  handleClose={handleCloseOrderCard}
                                  contractAddress={filterContract}
                                  strike={strike}
                                  expiry={exp}
                                  isCall={true}
                                  modalSelectNFTVisible={modalSelectNFTOrderId}
                                  setModalSelectNFTVisible={setModalSelectNFTOrderId}
                                  modalSelectedNFT={modalSelectedNFT}
                                  setModalSelectedNFT={setModalSelectedNFT}
                                  mode={mode}
                                  blockMapping={blockMapping}
                                  toggleTabBar={toggleTabBar}
                                  wallet={wallet}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    )}
                  </Grid>

                  {/* Divider */}
                  <Grid item xs={1} sx={{minHeight:'100%'}}>
                    <Divider orientation="vertical" />
                  </Grid>

                  {/* Puts Section */}
                  <Grid container item xs={4} columns={{xs: 2}} alignItems="stretch" sx={{minWidth:'50%'}}>
                    <Grid item xs={2}>
                      <Divider/>
                      <Divider sx={{marginTop:'20px', marginBottom:'20px'}} />
                    </Grid>

                    {expsOpen[exp] && (
                      <div style={{minWidth:'100%', margin:0, padding:0}}>
                        {Object.entries(bestOrders?.[exp]?.puts || {}).map(([strike, e], index) => (
                          <Grid container item style={{width: '100%', margin: 0, padding: 0}} key={`put-${exp}-${strike}`}>
                            <Grid container style={{width: '100%', display: 'flex', alignItems: 'center', minWidth: '100%'}}>
                              {/* Strike label/value column */}
                              <Grid item style={{width: '10%', flexShrink: 0}}>
                                {index === 0 && (
                                  <Typography size="small" style={{fontSize: 10, textAlign: 'left'}} sx={{marginLeft:1}}>
                                    Strike
                                  </Typography>
                                )}
                                <Typography size="small" style={{fontSize: 10, textAlign: 'left'}} sx={{marginLeft:1}}>
                                  {strike}
                                </Typography>
                              </Grid>
                              
                              {/* Bid card */}
                              <Grid item style={{width: '45%', flexGrow: 1}}>
                                <MemoizedAnimatedOrderCard 
                                  theme={theme} 
                                  order={e.bestBid} 
                                  isBid={true}  
                                  userAccount={userAccount} 
                                  curr={curr} 
                                  option={e} 
                                  openModalId={openModalId}
                                  setOpenModalId={setOpenModalId}
                                  handleClose={handleCloseOrderCard}
                                  contractAddress={filterContract}
                                  strike={strike}
                                  expiry={exp}
                                  isCall={false}
                                  modalSelectNFTVisible={modalSelectNFTOrderId}
                                  setModalSelectNFTVisible={setModalSelectNFTOrderId}
                                  modalSelectedNFT={modalSelectedNFT}
                                  setModalSelectedNFT={setModalSelectedNFT}
                                  mode={mode}
                                  blockMapping={blockMapping}
                                  toggleTabBar={toggleTabBar}
                                  wallet={wallet}
                                />
                              </Grid>

                              {/* Offer card */}
                              <Grid item style={{width: '45%', flexGrow: 1}}>
                                <MemoizedAnimatedOrderCard 
                                  theme={theme} 
                                  order={e.bestOffer} 
                                  isBid={false}  
                                  userAccount={userAccount} 
                                  curr={curr}  
                                  option={e} 
                                  openModalId={openModalId}
                                  setOpenModalId={setOpenModalId}
                                  handleClose={handleCloseOrderCard}
                                  contractAddress={filterContract}
                                  strike={strike}
                                  expiry={exp}
                                  isCall={false}
                                  modalSelectNFTVisible={modalSelectNFTOrderId}
                                  setModalSelectNFTVisible={setModalSelectNFTOrderId}
                                  modalSelectedNFT={modalSelectedNFT}
                                  setModalSelectedNFT={setModalSelectedNFT}
                                  mode={mode}
                                  blockMapping={blockMapping}
                                  toggleTabBar={toggleTabBar}
                                  wallet={wallet}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    )}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </CardContent>
  </Card>
);
}



