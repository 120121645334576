import React, { createContext, useContext, useState, useEffect,useMemo, useCallback, useRef } from 'react';
import { supabase, getOptions, getOwners, getOptionImage, getOrders, getBlockGas, getUniqueUnderlyingFromOrders } from '../supaBaseFuncs';
import { ethers } from 'ethers';
import debounce from 'lodash/debounce';
import {ZtrikeAddress, NFTAddress, MarketplaceAddress} from '../ethContracts';
import { 
  getOrder, 
  getNftData,
  getActiveOrderIdByOptionId, 
  getUniqueWriters,
  getContractData
} from '../supaBaseFuncs';


const roundDownToNearest = (value, step) => Math.floor(value / step) * step;
const roundUpToNearest = (value, step) => Math.ceil(value / step) * step;


const DataContext = createContext();

export function useData() {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
}

// Add these helper functions before the DataProvider component
const filterOrders = (orders, filterContract) => {
  if (!orders || !filterContract) return [];
  
  return orders.filter(order => 
    order.underlyingContract?.toLowerCase() === filterContract.toLowerCase() &&
    !order.isFilled &&
    !order.isCancelled
  );
};

// This is for OrderViewContract
const organizeOrders = (orders, expiries) => {
  if (!orders || !expiries) return {};
  
  const organized = {};
  expiries.forEach(expiry => {
    organized[expiry] = { calls: {}, puts: {} };
  });

  orders.forEach(order => {
    const expiry = order.expiry;
    if (!organized[expiry]) return;

    const type = order.isCall ? 'calls' : 'puts';
    const strike = order.strike;
    
    if (!organized[expiry][type][strike]) {
      organized[expiry][type][strike] = { bids: [], offers: [] };
    }
    
    if (order.isOffer) {
      organized[expiry][type][strike].offers.push(order);
    } else {
      organized[expiry][type][strike].bids.push(order);
    }
  });

  return organized;
};

const getBestOrders = (organizedOrders) => {
  if (!organizedOrders) return {};
  
  const bestOrders = {};
  
  Object.entries(organizedOrders).forEach(([expiry, types]) => {
    bestOrders[expiry] = { calls: {}, puts: {} };
    
    ['calls', 'puts'].forEach(type => {
      Object.entries(types[type]).forEach(([strike, orders]) => {
        bestOrders[expiry][type][strike] = {
          bestBid: orders.bids.reduce((best, order) => 
            (!best || order.price > best.price) ? order : best, null),
          bestOffer: orders.offers.reduce((best, order) => 
            (!best || order.price < best.price) ? order : best, null)
        };
      });
    });
  });
  
  return bestOrders;
};



// Add this function to get unique expiries
const getUniqueExpiriesForContract = async (contract, currentBlock) => {
  if (!contract) return [];

  try {
    const { data: options } = await supabase
      .from('options')
      .select('expiry')
      .gt('expiry', currentBlock)
      .ilike('contractAddress', contract)
      .order('expiry', { ascending: true });

    const { data: orders } = await supabase
      .from('orders')
      .select('expiry')
      .eq('isFilled', false)
      .eq('isCancelled', false)
      .gt('validUntil', currentBlock)
      .eq('isGeneral', true)
      .ilike('underlyingContract', contract);

    const optionExpiries = options?.map(o => o.expiry) || [];
    const orderExpiries = orders?.map(o => o.expiry) || [];
    
    return [...new Set([...optionExpiries, ...orderExpiries])].sort((a, b) => a - b);
  } catch (error) {
    console.error('Error fetching expiries:', error);
    return [];
  }
};

export function DataProvider({ children }) {
  const initialFetchDone = useRef(false);
  const subscriptionsRef = useRef([]);

  const [userAccount, setUserAccount] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [initialized, setInitialized] = useState(false);

  // Add these state declarations
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [expiries, setExpiries] = useState([]);
  const [organizedOrders, setOrganizedOrders] = useState({});
  const [bestOrders, setBestOrders] = useState({});
  const [filterContract, setFilterContract] = useState(NFTAddress);
  const [uniqueContracts, setUniqueContracts] = useState([]);

  const [currentBlock, setCurrentBlock] = useState(0);
  const [blockInitialized, setBlockInitialized] = useState(false);
  



  const [currentGas, setCurrentGas] = useState(0);
  const [optionsData, setOptionsData] = useState({
    options: [],
    uniqueContracts: [],
    ownersData: [],
    uniqueOwners: [],
    orderDetails: {}
  });
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [options, setOptions] = useState([]);


  // Shared filter states between OrdersView and OptionsView
  const [showExpired, setShowExpired] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);
  const [showPuts, setShowPuts] = useState(true);
  const [showCalls, setShowCalls] = useState(true);
  const [filterCreator, setFilterCreator] = useState('');

  // OptionsView-specific filter states
  const [showMyOptions, setShowMyOptions] = useState(false);
  const [strikeRange, setStrikeRange] = useState([0, Number.MAX_SAFE_INTEGER]);
  const [expiryRange, setExpiryRange] = useState([0, Number.MAX_SAFE_INTEGER]);
  const [possibleStrikes, setPossibleStrikes] = useState([0, Number.MAX_SAFE_INTEGER]);
  const [possibleExpiries, setPossibleExpiries] = useState([0, Number.MAX_SAFE_INTEGER]);
  const [filterOwner, setFilterOwner] = useState('');
  const [uniqueWriters, setUniqueWriters] = useState([]);
  // Visual range states
  const [visualStrikeRange, setVisualStrikeRange] = useState(strikeRange);
  const [visualExpiryRange, setVisualExpiryRange] = useState(expiryRange);
  const [urlFilterOptionId, setUrlFilterOptionId] = useState('');
  const [showSpecificOption, setShowSpecificOption] = useState('');

  // OrdersView-specific filter states
  const [showMyOrders, setShowMyOrders] = useState(false);
  const [showFilled, setShowFilled] = useState(false);
  const [showOnlyMatching, setShowOnlyMatching] = useState('');
  const [expiredOrdersCount, setExpiredOrdersCount] = useState(0);
  const [batchCancelLoading, setBatchCancelLoading] = useState(false);
  const [batchCancelStatus, setBatchCancelStatus] = useState('');
  const [isEffectivelyCancelledMapReady, setIsEffectivelyCancelledMapReady] = useState(false);
  const [effectivelyCancelledMap, setEffectivelyCancelledMap] = useState({});
  const [expiredOrders, setExpiredOrders] = useState([]);
  const [debouncedStrikeRange, setDebouncedStrikeRange] = useState([0, Number.MAX_SAFE_INTEGER]);
  const [debouncedExpiryRange, setDebouncedExpiryRange] = useState([0, Number.MAX_SAFE_INTEGER]);
  const [orderDetails, setOrderDetails] = useState({});
  const [activeOrderIds, setActiveOrderIds] = useState({});
  

  // NFTCard state
  const [nftData, setNftData] = useState({});
  const [nftImages, setNftImages] = useState({});
  const [imageLoadingStates, setImageLoadingStates] = useState({});
  //ContractCard state
  const [contractData, setContractData] = useState({});



  // Add best matches state with initialization
  const [bestMatches, setBestMatches] = useState(() => {
    if (Array.isArray(orders)) {
      const matches = {};
      orders.forEach(order => {
        matches[order._orderId] = { price: '', matchingOrderId: '' };
      });
      return matches;
    }
    return {};
  });


  const isEffectivelyCancelled = async (order) => {
    if (!order.isOffer || !blockInitialized) return false;
    
    try {
      const option = options.find(opt => opt.optionId === order.optionId);
      if (option && Number(option.expiry) <= Number(currentBlock)) {
        return true;
      }
  
      const optionOwnership = optionsData.ownersData.find(o => o.optionId === order.optionId);
      
      // If no ownership record found or owner is not the marketplace, consider it effectively cancelled
      return !optionOwnership || 
              optionOwnership.owner.toLowerCase() !== MarketplaceAddress.toLowerCase();
    } catch (error) {
      console.error('Error checking option ownership:', error);
      return true;
    }
  };

  // Add getExpiredOrders function
  const getExpiredOrders = useCallback((userAccount) => {
    if (!userAccount || !orders || !isEffectivelyCancelledMapReady) return [];
    
    return orders.filter(order => 
      order.orderCreator.toLowerCase() === userAccount.toLowerCase() &&
      !order.isFilled &&
      !order.isCancelled &&
      !effectivelyCancelledMap[order._orderId] &&
      (Number(order.validUntil) - currentBlock) < 1
    );
  }, [orders, isEffectivelyCancelledMapReady, effectivelyCancelledMap, currentBlock]);

  const calculateExpiredOrders = useCallback(() => {
    if (!orders || !userAccount) return 0;
    const expired = getExpiredOrders(userAccount);
    setExpiredOrders(expired);
    setExpiredOrdersCount(expired.length);
  }, [orders, currentBlock, isEffectivelyCancelledMapReady, effectivelyCancelledMap, getExpiredOrders, userAccount]);

  useEffect(() => {
    if (Array.isArray(orders) && userAccount) {
      calculateExpiredOrders();
    }
  }, [orders, userAccount, calculateExpiredOrders]);

  const getBestMatch = useCallback((optionId, isOffer, orderId) => {
    const price = findBestPrice(optionId, isOffer, orderId, true);
    const matchingOrderId = price !== '' ? findBestPrice(optionId, isOffer, orderId, false) : '';
    return { price, matchingOrderId };
}, [orders, currentBlock]);

const findBestPrice = useCallback((optionId, isOffer, orderId, returnPrice = true) => {
    // Filter out cancelled, expired, and filled orders
    const validOrders = orders.filter(order => 
        !order.isCancelled && 
        !order.isFilled &&  // Check for filled orders
        order._orderId != orderId &&
        (Number(order.validUntil) - currentBlock) >= 1 && // Check order expiry
        (Number(order.expiry) - currentBlock) >= 1  // Check option expiry
    );

    if (validOrders.length === 0) return '';

    if (!isOffer) { // If our order is a bid
        // Get the order details to match against
        const ourBid = orders.find(order => order._orderId === orderId);
        if (!ourBid){
            console.log("ourBid not found", orderId);
            return '';
        };

        // Filter offers that match our bid criteria
        const matchingOffers = validOrders.filter(order => {
            if (!order.isOffer) return false;
            
            // For specific bids, only match the exact optionId
            if (!ourBid.isGeneral) {
                return order.optionId === ourBid.optionId;
            }
            
            // For general bids, match based on option parameters
            return order.underlyingContract === ourBid.underlyingContract &&
                   order.isCall === ourBid.isCall &&
                   Number(order.strike) === Number(ourBid.strike) &&
                   Number(order.expiry) === Number(ourBid.expiry);
        });

        if (matchingOffers.length === 0) return '';
        
        // Find lowest matching offer
        const lowestOffer = matchingOffers.reduce((min, offer) => 
            Number(offer.price) < Number(min.price) ? offer : min
        );
        
        return returnPrice ? lowestOffer.price : lowestOffer._orderId;

    } else { // If our order is an offer
        // Filter for matching bids (both specific and general)
        const matchingBids = validOrders.filter(order => {
            if (order.isOffer) return false;
            
            // For specific bids, match exact optionId
            if (!order.isGeneral) {
                return order.optionId === optionId;
            }
            
            // For general bids, check if our offer matches their criteria
            const offerDetails = orders.find(o => o._orderId === orderId);
            return order.underlyingContract === offerDetails.underlyingContract &&
                   order.isCall === offerDetails.isCall &&
                   Number(order.strike) === Number(order.strike) &&
                   Number(order.expiry) === Number(order.expiry);
        });

        if (matchingBids.length === 0) return '';
        
        // Find highest matching bid
        const highestBid = matchingBids.reduce((max, bid) => 
            Number(bid.price) > Number(max.price) ? bid : max
        );
        
        return returnPrice ? highestBid.price : highestBid._orderId;
    }
}, [orders, currentBlock]);


  // Modified filterOrders function with default parameters
  const filterOrders = useCallback((ordersToFilter) => {
    if (!Array.isArray(ordersToFilter)) return [];
  
    return ordersToFilter.filter(order => {
      if (showOnlyMatching) {
        if (order._orderId.toString() === showOnlyMatching) return true;
        const bestMatch = bestMatches[showOnlyMatching];
        if (bestMatch?.matchingOrderId) {
          return order._orderId.toString() === bestMatch.matchingOrderId.toString();
        }
        return false;
      }
  
      const callPutMatch = (showCalls && order.isCall) || (showPuts && !order.isCall);
      const contractMatch = !filterContract || order.underlyingContract.toLowerCase() === filterContract.toLowerCase();
      const creatorMatch = !filterCreator || order.orderCreator.toLowerCase() === filterCreator.toLowerCase();
      const myOrdersMatch = !showMyOrders || (userAccount && order.orderCreator.toLowerCase() === userAccount.toLowerCase());
      const filledMatch = showFilled || !order.isFilled;
      const isExpired = (Number(order.validUntil) - currentBlock) < 1;
      const isCancelled = order.isCancelled;
      const statusMatch = 
            (!isExpired && !isCancelled) || 
            (isCancelled && showCancelled) || 
            (isExpired && !isCancelled && showExpired);

      return callPutMatch && contractMatch && creatorMatch && myOrdersMatch && 
             statusMatch && filledMatch;
    });
  }, [
    showOnlyMatching,
    bestMatches,
    showCalls,
    showPuts,
    filterContract,
    filterCreator,
    showMyOrders,
    userAccount,
    showExpired,
    currentBlock,
    showCancelled,
    showFilled
  ]);




  // Add function to load image for an option
  const loadOptionImage = async (option) => {
    try {
      const optImg = await getOptionImage(option.optionId);
      return {
        ...option,
        publicURL: optImg?.publicUrl
      };
    } catch (err) {
      console.error(`Error loading image for option ${option.optionId}:`, err);
      return option;
    }
  };

  // Memoize the update handler to prevent unnecessary recreation
  const handleDataUpdate = useCallback(
    (table, payload) => {
      console.log(`Processing ${table} update:`, payload);
      const { new: newData, old: oldData, eventType } = payload;

      switch (table) {
        case 'options': {
          // Get the current owner
          const owner = optionsData.ownersData.find(o => o.optionId === newData?.optionId)?.owner;
          const optionWithOwner = {
            ...newData,
            ownerOf: owner || '0x0000000000000000000000000000000000000000'
          };

          // Load image and update state
          loadOptionImage(optionWithOwner).then(optionWithImage => {
            setOptionsData(prev => {
              let updatedOptions = [...prev.options];
              
              switch(eventType) {
                case 'INSERT':
                  updatedOptions = [...updatedOptions, optionWithImage];
                  break;
                case 'UPDATE': {
                  const index = updatedOptions.findIndex(o => o.optionId === newData.optionId);
                  if (index >= 0) {
                    updatedOptions[index] = optionWithImage;
                  }
                  break;
                }
                case 'DELETE':
                  updatedOptions = updatedOptions.filter(o => o.optionId !== oldData.optionId);
                  break;
              }

              const uniqueContracts = [...new Set(
                updatedOptions
                  .map(opt => opt.contractAddress?.toLowerCase())
                  .filter(addr => addr && ethers.isAddress(addr))
              )];

              return {
                ...prev,
                options: updatedOptions,
                uniqueContracts
              };
            });

            // Update legacy state
            setOptions(prev => {
              switch(eventType) {
                case 'INSERT':
                  return [...prev, optionWithImage];
                case 'UPDATE': {
                  const index = prev.findIndex(o => o.optionId === newData.optionId);
                  if (index >= 0) {
                    const updated = [...prev];
                    updated[index] = optionWithImage;
                    return updated;
                  }
                  return prev;
                }
                case 'DELETE':
                  return prev.filter(o => o.optionId !== oldData.optionId);
                default:
                  return prev;
              }
            });
          });
          break;
        }
        case 'orders': {
          setOrders(prev => {
            switch(eventType) {
              case 'INSERT':
                return [...prev, newData];
              case 'UPDATE': {
                const index = prev.findIndex(o => o._orderId === newData._orderId);
                if (index >= 0) {
                  const updated = [...prev];
                  updated[index] = newData;
                  return updated;
                }
                return prev;
              }
              case 'DELETE':
                return prev.filter(o => o._orderId !== oldData._orderId);
              default:
                return prev;
            }
          });
          break;
        }
        case 'owners': {
          setOptionsData(prev => {
            const updatedOwnersData = prev.ownersData.map(owner => 
              owner.optionId === newData.optionId ? newData : owner
            );

            const updatedOptions = prev.options.map(option => {
              if (option.optionId === newData.optionId) {
                return {
                  ...option,
                  ownerOf: newData.owner
                };
              }
              return option;
            });

            const uniqueOwners = [...new Set(
              updatedOwnersData
                .map(owner => owner.owner?.toLowerCase())
                .filter(addr => addr && ethers.isAddress(addr))
            )];

            return {
              ...prev,
              options: updatedOptions,
              ownersData: updatedOwnersData,
              uniqueOwners
            };
          });

          setOptions(prev => 
            prev.map(option => 
              option.optionId === newData.optionId
                ? { ...option, ownerOf: newData.owner }
                : option
            )
          );
          break;
        }
      }
    },
    [optionsData.ownersData]
  );

  const handleBlockUpdate = useCallback(async () => {
    try {
        const [blockNumber, gasPrice] = await getBlockGas();
        console.log("Block update received:", blockNumber, gasPrice);
        setCurrentBlock(blockNumber);
        setCurrentGas(gasPrice);
        if (!blockInitialized) {
          setBlockInitialized(true);
        }
      } catch (error) {
        console.error("Error updating block info:", error);
      }
    }, [blockInitialized]);
  
    // Add initial block fetch
    useEffect(() => {
      handleBlockUpdate();
    }, []); // Run once on mount


//------------------------------------------------------------------
  // Set up subscriptions only once when component mounts
  useEffect(() => {
    if (!initialized) return;

    console.log("Setting up Supabase subscriptions in DataContext");
    
    // Clean up any existing subscriptions
    if (subscriptionsRef.current.length > 0) {
      console.log("Cleaning up existing subscriptions");
      subscriptionsRef.current.forEach(subscription => {
        subscription.unsubscribe();
      });
      subscriptionsRef.current = [];
    }

    // Add block subscription
    const blockChannel = supabase.channel('block-channel')
      .on('postgres_changes',
        { event: '*', schema: 'public', table: 'blockInfo' },
        () => {
          console.log('Block update received');
          handleBlockUpdate();
        }
      )
      .subscribe();

    // Options subscription
    const optionsChannel = supabase.channel('options-channel')
      .on('postgres_changes',
        { event: '*', schema: 'public', table: 'options' },
        payload => {
          console.log('Options update received:', payload);
          handleDataUpdate('options', payload);
        }
      )
      .subscribe();

    // Orders subscription
    const ordersChannel = supabase.channel('orders-channel')
      .on('postgres_changes',
        { event: '*', schema: 'public', table: 'orders' },
        payload => {
          console.log('Orders update received:', payload);
          handleDataUpdate('orders', payload);
        }
      )
      .subscribe();

    // Owners subscription
    const ownersChannel = supabase.channel('owners-channel')
      .on('postgres_changes',
        { event: '*', schema: 'public', table: 'owners' },
        payload => {
          console.log('Owners update received:', payload);
          handleDataUpdate('owners', payload);
        }
      )
      .subscribe();

    subscriptionsRef.current = [optionsChannel, ordersChannel, ownersChannel, blockChannel];

    // Initial block fetch
    handleBlockUpdate();

    return () => {
      console.log("Cleaning up Supabase subscriptions in DataContext");
      subscriptionsRef.current.forEach(subscription => {
        subscription.unsubscribe();
      });
      subscriptionsRef.current = [];
    };
  }, [initialized, handleBlockUpdate]); // Add handleBlockUpdate to dependencies

//------------------------------------------------------------------
  // Fetch initial data
  const fetchInitialData = useCallback(async () => {
    if (initialFetchDone.current) return;
    
    try {
      setIsLoading(true);
      console.log("Fetching initial data...");
      
      // Fetch all data in parallel
      const [fetchedOptions, fetchedOwners, fetchedOrders] = await Promise.all([
        getOptions(),
        getOwners(),
        getOrders()
      ]);

      // Process options data
      const ownersMap = fetchedOwners.reduce((acc, { optionId, owner }) => {
        acc[optionId] = owner;
        return acc;
      }, {});

      // Combine options with owners and load images
      const optionsWithOwners = await Promise.all(
        fetchedOptions.map(async option => {
          const withOwner = {
            ...option,
            ownerOf: ownersMap[option.optionId] || '0x0000000000000000000000000000000000000000'
          };
          return await loadOptionImage(withOwner);
        })
      );

      // Get unique contracts and owners
      const uniqueContracts = [...new Set(
        optionsWithOwners
          .map(opt => opt.contractAddress?.toLowerCase())
          .filter(addr => addr && ethers.isAddress(addr))
      )];
      
      const uniqueOwners = [...new Set(
        fetchedOwners
          .map(owner => owner.owner?.toLowerCase())
          .filter(addr => addr && ethers.isAddress(addr))
      )];

      // Update all states at once
      setOptionsData({
        options: optionsWithOwners,
        uniqueContracts,
        ownersData: fetchedOwners,
        uniqueOwners,
        orderDetails: {}
      });

      setOrders(fetchedOrders);
      setOptions(optionsWithOwners);
      setUniqueContracts(uniqueContracts);

      initialFetchDone.current = true;
      setInitialized(true);
    } catch (err) {
      console.error('Error fetching initial data:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Initial fetch
  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);


//------------------------------------------------------------------
// ORDERVIEW DATA
  const refreshData = useCallback(async (filterContract) => {
    if (!filterContract) return;
    
    try {
      const orders = await getOrders() || [];
      const unique = await getUniqueUnderlyingFromOrders() || [];
      const exps = await getUniqueExpiriesForContract(filterContract, currentBlock) || [];
      
      const filtered = filterOrders(orders, filterContract) || [];
      const organized = organizeOrders(filtered, exps) || {};
      const best = getBestOrders(organized) || {};

      setFilteredOrders(filtered);
      setOrganizedOrders(organized);
      setBestOrders(best);
      setExpiries(exps);
      setUniqueContracts(unique);
      
    } catch (error) {
      console.error("Error refreshing data:", error);
      setFilteredOrders([]);
      setOrganizedOrders({});
      setBestOrders({});
      setExpiries([]);
      setUniqueContracts([]);
    }
  }, [currentBlock]); // Add currentBlock to dependencies


  useEffect(() => {
    if (initialized && Array.isArray(orders)) {
      const filtered = filterOrders(orders);
      setFilteredOrders(filtered);
    }
  }, [initialized, orders, filterOrders]);

// Ordering Effects
    // Update best matches when orders change
    useEffect(() => {
      if (Array.isArray(orders)) {
        const matches = {};
        orders.forEach(order => {
          matches[order._orderId] = { price: '', matchingOrderId: '' };
        });
        setBestMatches(matches);
      }
    }, [orders]);

    // Update effectively cancelled map when orders change
    useEffect(() => {
      if (!Array.isArray(orders)) return;

      const updateEffectivelyCancelled = async () => {
          const effectivelyCancelledPromises = orders.map(order => isEffectivelyCancelled(order));
          const effectivelyCancelledResults = await Promise.all(effectivelyCancelledPromises);
          
          const newEffectivelyCancelledMap = {};
          orders.forEach((order, index) => {
              newEffectivelyCancelledMap[order._orderId] = effectivelyCancelledResults[index];
          });
          setEffectivelyCancelledMap(newEffectivelyCancelledMap);
          setIsEffectivelyCancelledMapReady(true);
      };

      updateEffectivelyCancelled();
  }, [orders, blockInitialized]);

    // Update best matches when orders change
    useEffect(() => {
      if (!Array.isArray(orders)) return;

      const matches = {};
      orders.forEach(order => {
          matches[order._orderId] = getBestMatch(
              order.optionId,
              order.isOffer,
              order._orderId
          );
      });
      setBestMatches(matches);
  }, [orders, currentBlock]);

    // Calculate expired orders when necessary
    useEffect(() => {
      if (Array.isArray(orders) && orders.length > 0 && isEffectivelyCancelledMapReady) {
          calculateExpiredOrders();
      }
  }, [orders, isEffectivelyCancelledMapReady, calculateExpiredOrders]);


    useEffect(() => {
        calculateExpiredOrders();
    }, [calculateExpiredOrders, orders, isEffectivelyCancelledMapReady]);


//------------------------------------------------------------------
// OptionsView data handling
const sortOptions = (options, userAccount, orderDetails, MarketplaceAddress) => {
  return options.sort((a, b) => {
    const aIsOwner = a.ownerOf?.toLowerCase() === userAccount?.toLowerCase();
    const bIsOwner = b.ownerOf?.toLowerCase() === userAccount?.toLowerCase();
    
    const aOrder = orderDetails[a.optionId]?.[0];
    const bOrder = orderDetails[b.optionId]?.[0];
    
    const aIsOrderCreator = a.ownerOf?.toLowerCase() === MarketplaceAddress?.toLowerCase() && 
                          aOrder?.orderCreator?.toLowerCase() === userAccount?.toLowerCase();
    const bIsOrderCreator = b.ownerOf?.toLowerCase() === MarketplaceAddress?.toLowerCase() && 
                          bOrder?.orderCreator?.toLowerCase() === userAccount?.toLowerCase();
    
    if (!!(aIsOwner || aIsOrderCreator) && !(bIsOwner || bIsOrderCreator)) return -1;
    if (!(aIsOwner || aIsOrderCreator) && !!(bIsOwner || bIsOrderCreator)) return 1;
    
    return a.optionId - b.optionId;
  });
};

useEffect(() => {
  if (!options || options.length === 0 || !blockInitialized) {
    console.log("No options available yet, skipping range setup");
    return;
  }

  // Remove the initialized check
  try {
    const strikes = options.map(opt => {
      try {
        const strikeValue = typeof opt.strike === 'string' ? opt.strike : opt.strike.toString();
        return parseFloat(ethers.formatEther(strikeValue));
      } catch (e) {
        console.error("strikeCheck: Error formatting strike:", e, opt.strike);
        return 0;
      }
    }).filter(strike => !isNaN(strike));
    const expiries = options.map(opt => Number(opt.expiry))
                           .filter(expiry => !isNaN(expiry));
    
    if (strikes.length > 0 && expiries.length > 0) {
      const minStrike = roundDownToNearest(Math.min(...strikes), 0.05);
      const maxStrike = roundUpToNearest(Math.max(...strikes), 0.05);
      const minExpiry = currentBlock;
      const maxExpiry = roundUpToNearest(Math.max(...expiries), 10000);

      setPossibleStrikes([minStrike, maxStrike]);
      setPossibleExpiries([minExpiry, maxExpiry]);
      
      // Only set ranges if they haven't been set before
      if (strikeRange[0] === 0 && strikeRange[1] === Number.MAX_SAFE_INTEGER) {
        setStrikeRange([minStrike, maxStrike]);
        setVisualStrikeRange([minStrike, maxStrike]);
        setDebouncedStrikeRange([minStrike, maxStrike]);
      }
      
      if (expiryRange[0] === 0 && expiryRange[1] === Number.MAX_SAFE_INTEGER) {
        setExpiryRange([minExpiry, maxExpiry]);
        setVisualExpiryRange([minExpiry, maxExpiry]);
        setDebouncedExpiryRange([minExpiry, maxExpiry]);
      }
    }
  } catch (e) {
    console.error("strikeCheck: Error setting up ranges:", e);
  }
}, [options, blockInitialized, currentBlock]); // Only depend on options changes

  // Fetch unique writers effect
  useEffect(() => {
    const fetchUniqueAddresses = async () => {
      const writers = await getUniqueWriters(filterContract || null);
      setUniqueWriters(writers);
    };

    fetchUniqueAddresses();
  }, [filterContract]);

  // Fetch active order IDs effect
  useEffect(() => {
    const fetchActiveOrderIds = async () => {
      const ids = {};
      for (const option of options) {
        const orderId = await getActiveOrderIdByOptionId(option.optionId);
        ids[option.optionId] = orderId;
      }
      setActiveOrderIds(ids);
    };

    if (options?.length > 0) {
      fetchActiveOrderIds();
    }
  }, [options]);


  // Fetch order details effect
  useEffect(() => {
    const fetchOrderDetails = async () => {
      const details = {};
      for (const optionId in activeOrderIds) {
        if (activeOrderIds[optionId]) {
          const order = await getOrder(activeOrderIds[optionId]);
          details[optionId] = order;
        }
      }
      setOrderDetails(details);
    };

    if (Object.keys(activeOrderIds).length > 0) {
      fetchOrderDetails();
    }
  }, [activeOrderIds]);


  // Memoize filtered options
  const filteredOptions = useMemo(() => {
    if (!options?.length) return [];

    return options.filter(option => {
      try {
        if (urlFilterOptionId !== '') {
          return Number(option.optionId) === urlFilterOptionId;
        }

        const optionState = {
          optionId: option.optionId,
          isCancelled: option.isCancelled,
          ownerOf: option.ownerOf?.toLowerCase(),
          isZeroAddress: option.ownerOf?.toLowerCase() === '0x0000000000000000000000000000000000000000',
          contractAddress: option.contractAddress?.toLowerCase(),
          optWriter: option.optWriter?.toLowerCase(),
          expiry: Number(option.expiry),
          strike: parseFloat(ethers.formatEther(option.strike?.toString() || '0')),
          isCall: option.isCall
        };

        const contractMatch = !filterContract || optionState.contractAddress === filterContract?.toLowerCase();
        const creatorMatch = !filterCreator || optionState.optWriter === filterCreator?.toLowerCase();
        const expiryMatch = showExpired || (optionState.expiry - currentBlock) >= 1;
        const cancelledMatch = showCancelled ? true : !optionState.isCancelled && !optionState.isZeroAddress;
        const putMatch = showPuts && !optionState.isCall;
        const callMatch = showCalls && optionState.isCall;
        const optionTypeMatch = putMatch || callMatch;

        const currentOrder = orderDetails[option.optionId]?.[0];
        const myOptionsMatch = !showMyOptions || !userAccount || (
          optionState.ownerOf === userAccount?.toLowerCase() || 
          optionState.optWriter === userAccount?.toLowerCase() || 
          (optionState.ownerOf === MarketplaceAddress?.toLowerCase() && 
           currentOrder?.orderCreator?.toLowerCase() === userAccount?.toLowerCase())
        );

        const strikeInRange = !isNaN(optionState.strike) && 
          optionState.strike >= debouncedStrikeRange[0] && 
          optionState.strike <= debouncedStrikeRange[1];

          const expiryInRange = showExpired ? (
            // When showing expired options, only check upper bound
            optionState.expiry <= debouncedExpiryRange[1]
        ) : (
            // When not showing expired options, check both bounds
            optionState.expiry >= debouncedExpiryRange[0] && 
            optionState.expiry <= debouncedExpiryRange[1]
        );
        
        const ownerMatch = !filterOwner || optionState.ownerOf === filterOwner?.toLowerCase();

        return contractMatch && creatorMatch && expiryMatch && 
               cancelledMatch && myOptionsMatch && ownerMatch && 
               strikeInRange && expiryInRange && optionTypeMatch;
      } catch (e) {
        console.error("Error filtering option:", e, option);
        return false;
      }
    });
  }, [
    options,
    showExpired,
    showCancelled,
    showMyOptions,
    showPuts,
    showCalls,
    filterContract,
    filterCreator,
    filterOwner,
    currentBlock,
    userAccount,
    urlFilterOptionId,
    debouncedStrikeRange,
    debouncedExpiryRange,
    orderDetails,
    MarketplaceAddress
  ]);

  // Memoize sorted options
  const sortedOptions = useMemo(() => {
    return userAccount ? sortOptions(filteredOptions, userAccount, orderDetails, MarketplaceAddress) : filteredOptions;
  }, [filteredOptions, userAccount, orderDetails, MarketplaceAddress]);


  const debouncedSetStrikeRange = useCallback(
    debounce((newValue) => {
      setDebouncedStrikeRange(newValue);
    }, 100),
    []
  );

  const debouncedSetExpiryRange = useCallback(
    debounce((newValue) => {
      setDebouncedExpiryRange(newValue);
    }, 100),
    []
  );


//------------------------------------------------------------------
// NFT Card data

// Add function to fetch NFT data
const IPFS_GATEWAY = "https://ipfs.io/ipfs/";


const fetchNftData = useCallback(async (contractAddress, nftId) => {
  const key = `${contractAddress}-${nftId}`;
  if (!nftData[key]) {
    try {
      const data = await getNftData(contractAddress, nftId);
      setNftData(prev => ({
        ...prev,
        [key]: data[0]
      }));
    } catch (error) {
      console.error('Error fetching NFT data:', error);
    }
  }
}, [nftData]);

// Add to useEffect that handles initial data loading
useEffect(() => {
  if (initialized && options.length > 0) {
    options.forEach(option => {
      fetchNftData(option.contractAddress, option.isCall ? option.underlyingId : 0);
    });
  }
}, [initialized, options, fetchNftData]);

// Add image loading functions
const preloadImage = useCallback((src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}, []);

const getImageFromMetadata = useCallback(async (tokenURI, key) => {
  try {
    setImageLoadingStates(prev => ({ ...prev, [key]: true }));
    
    // Handle IPFS URI for metadata
    let fetchUrl = tokenURI;
    if (tokenURI.startsWith('ipfs://')) {
      fetchUrl = IPFS_GATEWAY + tokenURI.substring(7);
    }

    const response = await fetch(fetchUrl).then(data => data.clone().json());
    let imageUrl;

    try {
      // Specification #1
      const img = response["properties"]["image"]["description"];
      imageUrl = img.startsWith('ipfs://') ? IPFS_GATEWAY + img.substring(7) : img;
    } catch {
      // Specification #2
      imageUrl = response["image"];
      if (imageUrl.startsWith('ipfs://')) {
        imageUrl = IPFS_GATEWAY + imageUrl.substring(7);
      }
    }

    await preloadImage(imageUrl);
    setNftImages(prev => ({ ...prev, [key]: imageUrl }));
    setImageLoadingStates(prev => ({ ...prev, [key]: false }));
  } catch (error) {
    console.error('Error loading image:', error);
    setImageLoadingStates(prev => ({ ...prev, [key]: false }));
  }
}, [preloadImage]);

// Add to useEffect that handles NFT data loading
useEffect(() => {
  const loadNftData = async (contractAddress, nftId) => {
    const key = `${contractAddress}-${nftId}`;
    if (!nftData[key]) {
      try {
        const data = await getNftData(contractAddress, nftId);
        if (data[0]) {
          setNftData(prev => ({ ...prev, [key]: data[0] }));
          // Load image once we have the NFT data
          await getImageFromMetadata(data[0].token_uri, key);
        }
      } catch (error) {
        console.error('Error fetching NFT data:', error);
      }
    }
  };

  if (initialized && options.length > 0) {
    options.forEach(option => {
      loadNftData(option.contractAddress, option.isCall ? option.underlyingId : 0);
    });
  }
}, [initialized, options, getImageFromMetadata]);


//------------------------------------------------------------------
// CONTRACTCARD DATA
const fetchContractData = useCallback(async (contractAddress) => {
  if (!contractAddress || contractData[contractAddress]) return;
  
  try {
    console.log('Fetching contract data for:', contractAddress); // Debug log
    const data = await getContractData(contractAddress);
    console.log('Received contract data:', data); // Debug log
    
    if (data[0]) {
      setContractData(prev => ({
        ...prev,
        [contractAddress]: data[0]
      }));
    }
  } catch (error) {
    console.error('Error fetching contract data:', error);
  }
}, [contractData]);

// In the useEffect that initializes contract data
useEffect(() => {
  if (initialized && options.length > 0) {
    console.log('Initializing contract data for options:', options); // Debug log
    const uniqueContracts = [...new Set(options.map(opt => opt.contractAddress))];
    console.log('Unique contracts found:', uniqueContracts); // Debug log
    uniqueContracts.forEach(contractAddress => {
      fetchContractData(contractAddress);
    });
  }
}, [initialized, options, fetchContractData]);



//------------------------------------------------------------------
// RETURN DATA

  const filterStates = {
    // Shared filters
    showExpired,
    showCancelled,
    showPuts,
    showCalls,
    filterCreator,
    // OptionsView-specific
    showMyOptions,
    strikeRange,
    expiryRange,
    filterContract,
    // OrdersView-specific
    showMyOrders,
    showFilled,
    showOnlyMatching,
  };


  const filterActions = {
    // Shared actions
    setShowExpired,
    setShowCancelled,
    setShowPuts,
    setShowCalls,
    setFilterCreator,
    // OptionsView-specific
    setShowMyOptions,
    setStrikeRange,
    setExpiryRange,
    setFilterContract,
    debouncedSetStrikeRange,
    debouncedSetExpiryRange,
    // OrdersView-specific
    setShowMyOrders,
    setShowFilled,
    setShowOnlyMatching,
  };

    // OptionsView-specific actions
    const optionsFilterActions = {
      setShowMyOptions,
      setStrikeRange,
      setExpiryRange,
      setFilterOwner,
    };  

  const value = {
    filteredOrders,
    setFilteredOrders,
    expiries,
    organizedOrders,
    bestOrders,
    filterContract,
    setFilterContract,
    uniqueContracts,
    initialized,
    refreshData,
    isLoading,
    error,
    orders,
    options,
    currentBlock,
    currentGas,
    // Shared filter states and actions
    filterStates,
    filterActions,
    filterOrders,
    // OrderView states and actions
    expiredOrdersCount,
    setExpiredOrdersCount,
    batchCancelLoading,
    setBatchCancelLoading,
    batchCancelStatus,
    setBatchCancelStatus,
    isEffectivelyCancelledMapReady,
    setIsEffectivelyCancelledMapReady,
    effectivelyCancelledMap,
    setEffectivelyCancelledMap,
    bestMatches,
    setBestMatches,
    expiredOrders,
    getExpiredOrders,
    calculateExpiredOrders,
    isEffectivelyCancelled,
    getBestMatch,
    findBestPrice,
    userAccount,
    setUserAccount,
    // OptionsView states 
    options,
    filteredOptions,
    sortedOptions,
    orderDetails,
    activeOrderIds,
    uniqueContracts,
    showMyOptions,
    strikeRange,
    expiryRange,
    possibleStrikes,
    possibleExpiries,
    filterOwner,
    visualStrikeRange,
    setVisualStrikeRange,
    // OptionsView actions
    optionsFilterActions,
    urlFilterOptionId,
    setUrlFilterOptionId,
    showSpecificOption,
    setShowSpecificOption,
    nftData,
    fetchNftData,
    nftImages,
    imageLoadingStates,
    contractData,
    fetchContractData,
  };

// Return Context with data
  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
}

// Export the context itself if needed elsewhere
export { DataContext };