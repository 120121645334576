import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import ContractCard from './ContractCard';
import { useNFTData } from './NFTDataContext';

const SearchableContractInput = ({ contracts = [], onChange, value, label = "Underlying contract" }) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { nftData } = useNFTData();

  const handleInputChange = (event, newInputValue) => {
    console.log("Input changed:", newInputValue);
    // Only set inputValue if we're actually typing, not when selecting
    if (!value) {
      setInputValue(newInputValue);
    }
  };

  const handleChange = (event, newValue) => {
    console.log("Selection changed:", newValue);
    if (onChange) {
      onChange(newValue);
      // Clear input value when we select something
      setInputValue('');
    }
  };


  const getOptionLabel = (option) => {
    if (!option) return '';
    
    // If we have NFT data for this contract, use its name
    if (nftData && nftData[option] && nftData[option][0]) {
      const contractData = nftData[option][0];
      return contractData.name || contractData.symbol || String(option);
    }
    
    // Fallback to address
    return String(option);
  };

  const filterOptions = (contracts, { inputValue }) => {
    return Array.isArray(contracts) 
      ? contracts.filter((contract) => {
          const optionLabel = getOptionLabel(contract).toLowerCase();
          const searchValue = inputValue.toLowerCase();
          return optionLabel.includes(searchValue) || 
                 String(contract).toLowerCase().includes(searchValue);
        })
      : [];
  };

  return (
    <Autocomplete
    open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={Array.isArray(contracts) ? contracts : []}
      renderInput={(params) => (
        <Box 
          position="relative"
          onClick={() =>  {
            if (value && !inputValue) {
              setIsOpen(true);
            }
          }}
        >
          <TextField 
            {...params} 
            label={value && !inputValue ? '' : label}
            placeholder="All contracts"
            fullWidth
            sx={{
              '& .MuiInputBase-input': {
                height: '36px',
                // Only show input when there's no value or we're in input mode
                opacity: !value || inputValue ? 1 : 0,
              },
              // Preserve the clear button
              '& .MuiInputAdornment-root': {
                zIndex: 2
              }
            }}
          />
          {value && !inputValue && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={40} // Leave space for the clear button
              bottom={0}
              sx={{ 
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                paddingX: 2,
              }}
            >
              <ContractCard
                theme={{}}
                mode={'light'}
                contractAddress={value}
                nftData={nftData && nftData[value] ? nftData[value][0] : null}
                isDropdownMode={true}
              />
            </Box>
          )}
        </Box>
      )} /// end of renderInput
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={value || null}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => 
        option === value || 
        String(option).toLowerCase() === String(value).toLowerCase()
      }
      freeSolo
      filterOptions={filterOptions}
      openOnFocus
      clearOnBlur={false}
      blurOnSelect={false}
      size="small"
      sx={{ 
        width: '100%',
        '& .MuiFormControl-root': {
          paddingRight: '8px'
        }
      }}
      renderOption={(props, option) => {
        return (
          <Box
            key={`contract-${option}`}
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <ContractCard
              theme={{}}
              mode={'light'}
              contractAddress={option}
              nftData={nftData && nftData[option] ? nftData[option][0] : null}
              isDropdownMode={true}
            />
          </Box>
        );
      }}
    />
  );
};

export default SearchableContractInput;