import React, { createContext, useContext, useState } from 'react';

const ImageCacheContext = createContext();

export function useImageCache() {
  const context = useContext(ImageCacheContext);
  if (!context) {
    throw new Error('useImageCache must be used within an ImageCacheProvider');
  }
  return context;
}

export function ImageCacheProvider({ children }) {
  const [imageCache, setImageCache] = useState(new Map());

  const addToCache = (url, data) => {
    setImageCache(prev => new Map(prev).set(url, data));
  };

  const getFromCache = (url) => {
    return imageCache.get(url);
  };

  return (
    <ImageCacheContext.Provider value={{ imageCache, addToCache, getFromCache }}>
      {children}
    </ImageCacheContext.Provider>
  );
}