import * as React from 'react';
import { Grid, Card, Box, Stack, Typography, IconButton, Link, ButtonGroup, Button } from '@mui/material';
import OSwhite from './logos/OSwhite.svg';
import OSBlack from './logos/OSblack.svg';
import ESlogo from './logos/etherscan-logo-light-circle.svg';
import ESlogoBlack from './logos/etherscan-logo-dark.svg';
import OptCardMedia from './OptCardMedia.js';
import NFTCard from './NFTCard.js';
import OptionNameCard from './OptionNameCard.js';
import RedeemPutManager from './RedeemPutManager';
import ModalCreateOrder from './ModalCreateOrder';
import { ZtrikeAddress } from './ethContracts';
import { ethers } from 'ethers';
import { useBlockNumber } from './contexts/BlockContext';

// Helper functions
const isAddressEqual = (addr1, addr2) => {
  if (!addr1 || !addr2) return false;
  return String(addr1).toLowerCase() === String(addr2).toLowerCase();
};

const formatAddress = (address, userAccount) => {
  if (!address) return '';
  if (isAddressEqual(address, userAccount)) return 'You';
  return `${String(address).substring(0, 5)}...${String(address).substring(38, 42)}`;
};

// Memoized sub-components
const MemoizedOptionNameCard = React.memo(OptionNameCard);
const MemoizedNFTCard = React.memo(NFTCard);
const MemoizedOptCardMedia = React.memo(OptCardMedia);

const MemoizedOptionCard = React.memo(({ 
  option, 
  mode,
  theme,
  userAccount,
  orderDetails,
  activeOrderIds,
  switchPC,
  nftSelections,
  setNftSelections,
  redeemFlowId,
  setRedeemFlowId,
  openModalId,
  handleOpen,
  handleClose,
  toggleTabBar,
  MarketplaceAddress,
  redeemOption,
  retrieveOption,
  regretOption
}) => {
  const { blockNumber: curr } = useBlockNumber();
  const e = option;

  // Memoized computed values
  const isOwner = React.useMemo(() => 
    isAddressEqual(e.ownerOf, userAccount),
    [e.ownerOf, userAccount]
  );

  const currentOrder = React.useMemo(() => 
    orderDetails[e.optionId]?.[0],
    [orderDetails, e.optionId]
  );

  const isOrderCreator = React.useMemo(() => 
    isAddressEqual(e.ownerOf, MarketplaceAddress) && 
    isAddressEqual(currentOrder?.orderCreator, userAccount),
    [e.ownerOf, MarketplaceAddress, currentOrder?.orderCreator, userAccount]
  );

  // Memoized handlers
  const handleRedeemClick = React.useCallback(() => {
    setRedeemFlowId(e.optionId);
  }, [e.optionId, setRedeemFlowId]);

  const handleRetrieveClick = React.useCallback(() => {
    retrieveOption(e.optionId, e.isCall);
  }, [e.optionId, e.isCall, retrieveOption]);

  const handleRegretClick = React.useCallback(() => {
    regretOption(e.optionId);
  }, [e.optionId, regretOption]);

  const handleOrderClick = React.useCallback((event) => {
    event.preventDefault();
    window.directLinkNavigation = true;
    setTimeout(() => {
      window.location.hash = `orders#${activeOrderIds[e.optionId]}`;
      toggleTabBar(3);
    }, 0);
  }, [e.optionId, activeOrderIds, toggleTabBar]);

  const handleExercisePut = React.useCallback(() => {
    redeemOption(
      e.optionId, 
      e.isCall, 
      nftSelections[e.optionId], 
      ethers.formatEther(String(e.strike)), 
      e.contractAddress
    );
  }, [e.optionId, e.isCall, nftSelections, e.strike, e.contractAddress, redeemOption]);

  // Memoized button states
  const buttonStates = React.useMemo(() => ({
    redeemDisabled: isAddressEqual(e.optWriter, e.ownerOf) || !isAddressEqual(e.ownerOf, userAccount),
    retrieveDisabled: e.expiry > curr || e.ownerOf === "0x0000000000000000000000000000000000000000",
    regretDisabled: !(isAddressEqual(e.optWriter, e.ownerOf) && isAddressEqual(e.ownerOf, userAccount))
  }), [e.optWriter, e.ownerOf, userAccount, e.expiry, curr]);

  return (
    <Grid item xs={12} sm={10} md={6} 
      style={{ 
        padding: 3, 
        paddingTop: 0, 
        marginTop: 0, 
        marginBottom: 10,
        minWidth: '190px', 
        maxWidth: '190px', 
        height: '610px'
      }}
    >
      <Card style={{
        backgroundImage: theme.custom.gradient1, 
        padding: 2, 
        height: '100%'
      }}>
        <Box style={{marginTop:0, paddingTop:0}}>
          <MemoizedOptCardMedia currOptionImage={e.publicURL} height='310px'/>
        </Box>

        <Stack direction="column" sx={{justifyItems:'left', alignItems:'flex-start'}}>
          {/* Header with Option ID and Links */}
          <Stack direction="row" spacing={0.5} align='bottom' sx={{ width: '100%', justifyContent: 'space-between' }}>
            <Typography 
              gutterBottom 
              variant="h6" 
              component="div" 
              sx={{
                paddingTop: '8px',
                fontSize: '10'
              }}
            >
              Option # {String(e.optionId)}
            </Typography>
            <Stack direction="row" spacing={0.5}>
              <IconButton 
                onClick={() => window.open(`https://testnets.opensea.io/assets/sepolia/${ZtrikeAddress}/${e.optionId}`)}
                sx={{ padding: '4px' }}
              >
                <img src={mode === 'light' ? OSBlack : OSwhite} alt="OpenSea" width={20} />
              </IconButton>
              <IconButton 
                onClick={() => window.open(`https://sepolia.etherscan.io/token/${ZtrikeAddress}?a=${e.optionId}`)}
                sx={{ padding: '4px' }}
              >
                <img src={mode === 'light' ? ESlogoBlack : ESlogo} alt="Etherscan" width={20} />
              </IconButton>
            </Stack>
          </Stack>

          <MemoizedOptionNameCard
            theme={theme}
            mode={mode}
            contractAddress={e.contractAddress}
            optionId={e.optionId}
            isCall={e.isCall}
            strike={e.strike}
            expiry={e.expiry}
            block={curr}
            userAccount={userAccount}
          />

          <MemoizedNFTCard
            contractAddress={e.contractAddress}
            nftid={e.isCall ? e.underlyingId : 'any'}
            mode={mode}
            theme={theme}
          />

          {/* Owner Info */}
          <Typography 
            variant="body2" 
            component="div" 
            style={{
              fontSize: 10,
              display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
              width: '100%'
            }} 
          >
            Owner: 
            {isAddressEqual(e.ownerOf, MarketplaceAddress) && currentOrder ? (
              <Link 
                color={e.isCall ? 'primary' : 'secondary'} 
                target="_blank" 
                href={`https://sepolia.etherscan.io/address/${currentOrder.orderCreator}`}
              >
                {formatAddress(currentOrder.orderCreator, userAccount)}
              </Link>
            ) : (
              <Link 
                color={e.isCall ? 'primary' : 'secondary'} 
                target="_blank" 
                href={`https://sepolia.etherscan.io/address/${e.ownerOf}`}
              >
                {e.ownerOf === "0x0000000000000000000000000000000000000000" ? 
                  "Burned" : formatAddress(e.ownerOf, userAccount)}
              </Link>
            )}
          </Typography>

          {/* Writer Info */}
          <Typography 
            variant="body2" 
            component="div" 
            style={{
              fontSize: 10,
              display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
              width: '100%'
            }} 
          >
            Writer: 
            <Link 
              color={e.isCall ? 'primary' : 'secondary'} 
              target="_blank" 
              href={`https://sepolia.etherscan.io/address/${e.optWriter}`}
            >
              {formatAddress(e.optWriter, userAccount)}
            </Link> 
          </Typography>


          <Typography 
  variant="body2" 
  component="div" 
  style={{
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    width: '100%',
    minHeight: '20px',  // Add this for consistent spacing
    marginBottom: '4px' // Add this for spacing before buttons
  }} 
>
  {activeOrderIds[e.optionId] && (
    <>
      Active in order: 
      <Link
        color={e.isCall ? 'primary' : 'secondary'}
        onClick={handleOrderClick}
        sx={{ 
          cursor: 'pointer', 
          ml: 0.5,
          display: 'inline-flex',
          alignItems: 'center'
        }}
      >
        #{activeOrderIds[e.optionId]}
      </Link>
    </>
  )}
</Typography>

          {/* Action Buttons */}
          <ButtonGroup style={{margin:0, minWidth:'100%', display:'flex'}}>
            <Button 
              size="small" 
              color='primary'
              variant='outlined'
              style={{padding:1}}
              sx={{flexGrow:1}}
              disabled={buttonStates.redeemDisabled}
              onClick={handleRedeemClick}
            >
              <Typography style={{fontSize:11, fontWeight:500}}>
                Redeem
              </Typography>
            </Button>
            
            <Button 
              size="small"
              color='primary'
              variant='outlined'
              style={{padding:1}}
              sx={{flexGrow:1}}
              disabled={buttonStates.retrieveDisabled}
              onClick={handleRetrieveClick}
            >
              <Typography style={{fontSize:11, fontWeight:500}}>
                Retrieve
              </Typography>
            </Button>
            
            <Button 
              size="small"
              color='primary'
              variant='outlined'
              style={{padding:1}}
              sx={{flexGrow:1}}
              disabled={buttonStates.regretDisabled}
              onClick={handleRegretClick}
            >
              <Typography style={{fontSize:11, fontWeight:500}}>
                Regret
              </Typography>
            </Button>
          </ButtonGroup>

          {/* Conditional Renders */}
          {((!e.isCall) && (redeemFlowId === e.optionId)) && (
            <>
              <Typography fontSize={8}>
                Select an NFT to exercise your put option
              </Typography>
              <RedeemPutManager
                options={[e]}
                userAccount={userAccount}
                switchPC={switchPC}
                disabled={(e.expiry < curr) || !isAddressEqual(e.ownerOf, userAccount)}
                curr={curr}
                nftSelections={nftSelections}
                setNftSelections={setNftSelections}
              />
            </>
          )}

          {((redeemFlowId === e.optionId) && (nftSelections[e.optionId] !== undefined)) && (
            <Box sx={{height: '25px', width:'100%', padding:'0 7px 7px 7px'}}>
              <Button 
                variant="contained"
                size='small'
                style={{
                  padding: '0',
                  width: "100%",
                  height: "100%",
                  fontSize:'12px'
                }}
                color={switchPC === 'Call' ? 'primary': 'secondary'}
                disabled={nftSelections[e.optionId] === undefined}
                onClick={handleExercisePut}
              >
                Exercise put {String(nftSelections[e.optionId])}
              </Button>
            </Box>
          )}

          {/* Sell/Bid Button */}
          <Box sx={{
            width:'100%', 
            display:'flex', 
            justifyContent:'center', 
            maxHeight:'30px',
            mt: 'auto'
          }}>
            <div style={{
              margin: 7,
              marginTop: 0,
              justifyContent: "center", 
              width: '100%',
              display: 'flex'
            }}>
              <Button 
                variant="outlined" 
                size="small" 
                disabled={!userAccount || (e.expiry < curr)} 
                onClick={() => handleOpen(e.optionId)} 
                color={isOwner ? 'primary' : 'secondary'}
                sx={{
                  border: 1,
                  borderColor: isOwner ? 'primary.main' : 'secondary.main',
                  width: '100%'
                }}
              >
                <Typography sx={{
                  fontSize: 12, 
                  color: !userAccount ? 'text.disabled' : (isOwner ? 'primary.main' : 'secondary.main')
                }}>
                  {isOwner ? 'Sell' : 'Bid'}
                </Typography>
              </Button>
              <ModalCreateOrder
                key={`modal-${e.optionId}-${userAccount}`}
                activeStrike={ethers.formatEther(String(e.strike))}
                activeExpiry={e.expiry}
                activePC={e.isCall ? 'call':'put'}
                bb={curr}
                switchPC={switchPC}
                activeUnderlyingID={e.underlyingId}
                optionID={e.optionId}
                underlyingContract={e.contractAddress}
                orderType={isOwner ? 'Offer' : 'Bid'}
                isSpecific={true}
                openT={openModalId === e.optionId}
                setOpenT={handleClose}
              />
            </div>
          </Box>
        </Stack>
      </Card>
    </Grid>
  );
}, (prevProps, nextProps) => {
  // Deep comparison function for critical props
  const arePropsEqual = () => {
    // Basic equality checks
    if (prevProps.option.optionId !== nextProps.option.optionId) return false;
    if (prevProps.mode !== nextProps.mode) return false;
    if (prevProps.userAccount !== nextProps.userAccount) return false;
    if (prevProps.redeemFlowId !== nextProps.redeemFlowId) return false;
    if (prevProps.openModalId !== nextProps.openModalId) return false;

    // Deep comparison for orderDetails
    const prevOrder = prevProps.orderDetails[prevProps.option.optionId]?.[0];
    const nextOrder = nextProps.orderDetails[nextProps.option.optionId]?.[0];
    if (!prevOrder !== !nextOrder) return false;
    if (prevOrder && nextOrder && prevOrder.orderId !== nextOrder.orderId) return false;

    // Compare activeOrderIds
    if (prevProps.activeOrderIds[prevProps.option.optionId] !== 
        nextProps.activeOrderIds[nextProps.option.optionId]) return false;

    // Compare nftSelections
    if (prevProps.nftSelections[prevProps.option.optionId] !== 
        nextProps.nftSelections[nextProps.option.optionId]) return false;

    return true;
  };

  return arePropsEqual();
});

export default MemoizedOptionCard; 