import React from 'react';
import { Typography, Stack, Box, Link } from '@mui/material';
import { ethers } from 'ethers';
import ethLogo from './logos/ethlogo.svg';

const FormattedPrice = ({ price, isBid, myOrder, isSpecific, specificOptionId, toggleTabBar, showLabels = true, showOptionLink = true }) => {
    const isPlaceholder = price === "-";
    
    let priceDisplay;
    if (!isPlaceholder) {
        const formattedPrice = ethers.formatEther(String(price));
        const [integerPart, decimalPart = ''] = formattedPrice.split('.');
        const paddedDecimal = (decimalPart + '0000').slice(0, 4);
        const firstTwoDecimals = paddedDecimal.slice(0, 2);
        const lastTwoDecimals = paddedDecimal.slice(2, 3);
        priceDisplay = (
            <Stack direction="column" alignItems="center" spacing={0}>
                <Stack direction="row" alignItems="baseline">
                    <Typography component="span" fontSize={10} sx={{color: myOrder ? 'primary.light' : 'inherit'}}>
                        {integerPart}.
                    </Typography>
                    <Typography component="span" fontSize={30} sx={{color: myOrder ? 'primary.light' : 'inherit'}}>
                        {firstTwoDecimals}
                    </Typography>
                    <Typography component="span" fontSize={10} sx={{color: myOrder ? 'primary.light' : 'inherit'}}>
                        {lastTwoDecimals}
                    </Typography>
                </Stack>
                {isSpecific && showOptionLink && (
                    <Link
                        component="button"
                        variant="body2"
                        onClick={(event) => {
                            event.preventDefault();
                            window.directLinkNavigation = true;
                            setTimeout(() => {
                                window.location.hash = `options#${specificOptionId}`;
                                toggleTabBar(1);
                            }, 0);
                        }}
                        sx={{ 
                            cursor: 'pointer',
                            fontSize: 6,
                            color: myOrder ? 'primary' : 'inherit',
                            marginTop: '-8px',
                            '&:hover': {
                                textDecoration: 'underline'
                            }
                        }}
                    >
                        {isBid?'Bid':'Offer'}{` for #${specificOptionId}`}
                    </Link>
                )}
            </Stack>
        );
    } else {
        priceDisplay = (
            <Typography fontSize={30} textAlign="center">-</Typography>
        );
    }

    const priceContainer = (
        <Box width="50px" display="flex" justifyContent="flex-end" alignItems="center">
            {priceDisplay}
        </Box>
    );

    const ethLogoElement = !isPlaceholder && (
        <div style={{paddingTop:10}}>
            <img src={ethLogo} 
                 height="20" 
                 width="20"
                 alt="ETH"/>
        </div>
    );

    const spacerElement = isPlaceholder && (
        <div style={{width: 20}} />
    );

    if (isBid) {
        return (
            <Stack direction="row" sx={{marginLeft:'auto'}}>
                {showLabels && (
                    <Typography size="small" style={{fontSize:10, paddingLeft:3}}>
                        Bid
                    </Typography>
                )}
                {ethLogoElement || spacerElement}
                {priceContainer}
            </Stack>
        );
    } else {
        return (
            <Stack direction="row">
                {ethLogoElement || spacerElement}
                {priceContainer}
                {showLabels && (
                    <Typography size="small" style={{fontSize:10, paddingLeft:3}}>
                        Ask
                    </Typography>
                )}
            </Stack>
        );
    }
};

export default FormattedPrice;