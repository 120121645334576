
var bs = require("black-scholes");

const BLOCKS_PER_HOUR = 300;
const HOURS_PER_YEAR = 24*365;
const BLOCKS_PER_YEAR = BLOCKS_PER_HOUR * HOURS_PER_YEAR;
const rf = 0.03; // Staking yield ~3%

export function blackScholes(S, K, tBlocks, ivol, isCall){
    let putCall = 'call';
    if (!isCall){
        putCall = 'put'
    }

    return bs.blackScholes(S, K, tBlocks/BLOCKS_PER_YEAR, ivol/100, rf, putCall.toLowerCase()).toFixed(3).toFixed(3)
}

// call it like: blackScholes(cFloor, cStrike, cExp, cVol/100, .05, activePC)
function normalDensity(x) {
    return Math.exp(-0.5 * x * x) / Math.sqrt(2 * Math.PI);
}

function calculateVega(S, K, t, vol, rf) {
    const d1 = (Math.log(S / K) + (rf + vol * vol / 2) * t) / (vol * Math.sqrt(t));
    return S * Math.sqrt(t) * normalDensity(d1);
}

export function bsIVOL(S, K, tBlocks, price, isCall) {
    // Input validation
    if (!S || !K || !tBlocks || !price) return "N/A";
    if (S <= 0 || K <= 0 || tBlocks <= 0 || price <= 0) return "N/A";

    const putCall = isCall ? 'call' : 'put';
    const t = tBlocks / BLOCKS_PER_YEAR;
    
    // For very short-dated options, return N/A
    if (t < 0.001) return "N/A";

    // Calculate intrinsic value
    const intrinsicValue = isCall ? Math.max(0, S - K) : Math.max(0, K - S);
    if (price < intrinsicValue) {
        return "-";
    }

    // Initial volatility guess based on moneyness
    const moneyness = S/K;
    let vol = moneyness < 0.8 || moneyness > 1.2 ? 2.0 : 0.8; // Start higher for OTM options
    
    const tolerance = 0.00001;
    const maxIterations = 100;
    
    // Binary search bounds
    let low = 0.01;
    let high = 10000.0; // Increased max vol to 1000%
    
    for (let i = 0; i < maxIterations; i++) {
        try {
            const price1 = bs.blackScholes(S, K, t, vol, rf, putCall);
            const diff = price1 - price;
            
            // Debug log
            //console.log(`Iteration ${i}: price1 ${price1} price ${price} tBlocks ${tBlocks} t ${t} S ${S} K ${K} rf ${rf} putCall ${putCall} vol ${vol}`);
            
            if (Math.abs(diff) < tolerance) {
                return Math.min(1000, vol * 100).toFixed(2);
            }
            
            // Binary search step
            if (price1 > price) {
                high = vol;
            } else {
                low = vol;
            }
            vol = (low + high) / 2;
            
        } catch (error) {
            //console.error('BS calculation error:', error);
            return "N/A";
        }
    }
    
    // If we get here, return best estimate if reasonable
    try {
        const finalPrice = bs.blackScholes(S, K, t, vol, rf, putCall);
        const impliedVol = vol * 100;
        if (Math.abs(finalPrice - price) < price * 0.15) { // Within 15% error
            return impliedVol > 1000 ? "∞" : impliedVol.toFixed(2);
        }
    } catch (error) {
        //console.error('Final BS calculation error:', error);
    }
    
    return "N/A";
}