import React from 'react';
import { Box, Card, Skeleton, Image, Stack, Typography } from '@mui/material';
import { useData } from './contexts/DataContext';
import VerifiedIcon from '@mui/icons-material/Verified';

const ContractCard = ({ theme, mode, contractAddress, nftid = 0, cardMode = "default" }) => {
    const { contractData, fetchContractData } = useData();

  const currentContractData = Object.keys(contractData)
    .find(key => key.toLowerCase() === contractAddress.toLowerCase())
    ? contractData[Object.keys(contractData)
        .find(key => key.toLowerCase() === contractAddress.toLowerCase())]
    : null;

  React.useEffect(() => {
    if (contractAddress && !Object.keys(contractData)
        .find(key => key.toLowerCase() === contractAddress.toLowerCase())) {
      fetchContractData(contractAddress);
    }
  }, [contractAddress, contractData, fetchContractData]);


  if (!currentContractData) {
    return (
      <Card sx={{height:'50px', width:'100%'}}>
        <Stack noWrap direction='row'>
          <Skeleton variant="circular" width={50} height={50} />
          <Box sx={{ width: '100%', ml: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Skeleton variant="text" width="60%" height={24} />
            <Skeleton variant="text" width="40%" height={16} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto', mr: 2 }}>
            <Skeleton variant="text" width={60} height={20} />
          </Box>
        </Stack>
      </Card>
    );
  }

  if (cardMode === "default") {
    return (
      <Card sx={{height:'50px', width:'100%'}}>
        <Stack noWrap direction='row'>
          <img 
            src={currentContractData.collection_logo} 
            height='50px'  
            sx={{ borderRadius: '85px', boxShadow: 0, maxWidth:'25%', minWidth:'50px' }}
          />
          <Box sx={{mr:'5px', border: 0, borderRadius: '16px', borderColor: '#000000', padding:1}}>
            <Typography variant='h6' align="left">
              {currentContractData.symbol}
              <VerifiedIcon style={{ width: '20px', height: '20px', marginLeft:'2px', verticalAlign: 'middle' }} />
            </Typography>
          </Box>
          <Box sx={{
            display:"flex", 
            flexDirection:"column", 
            justifyContent:"center",
            border: 0, 
            borderRadius: '16px', 
            borderColor: '#000000', 
            padding:1
          }}>
            <Typography align="left" sx={{fontSize:10}}>
              {String(currentContractData.token_address).slice(0,10)+'...'+String(currentContractData.token_address).slice(30,40)}
            </Typography>
          </Box>
          <Box sx={{
            display:"flex", 
            flexDirection:"column", 
            justifyContent:"center",
            border: 0, 
            borderRadius: '16px', 
            borderColor: '#000000', 
            padding:1
          }}>
            <Typography variant='h6' align="left" sx={{fontSize:10, color:'#878787'}}>
              {currentContractData.floor_price? 'Ξ10.62' : 'Ξ10.62'}
            </Typography>
          </Box>
        </Stack>
      </Card>
    );
  } else {
    return (
      <Card sx={{height:'60px', width:'100%', borderRadius:0, boxShadow:0, background:'transparent'}}>
        <Stack noWrap direction='column'>
          <Box sx={{
            mr:'5px', 
            border: 0, 
            borderRadius: '0px', 
            borderColor: '#000000', 
            padding:0, 
            height:'25px', 
            display:'flex', 
            justifyContent:'left', 
            alignItems:'center'
          }}>
            <Stack noWrap direction="row">
              <Typography variant='h6' align="left" sx={{fontSize:15, display:'flex', alignItems:'center', justifyContent:'space-between', mr:'5px'}}>
                <img src={currentContractData.collection_logo} height='25px'/>
              </Typography>
              <Typography variant='h6' align="left" sx={{fontSize:15, display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                {currentContractData.symbol}
                <VerifiedIcon style={{ width: '15px', height: '15px', marginLeft:'4px', verticalAlign: 'middle' }} />
              </Typography>
            </Stack>
          </Box>
          <Box sx={{
            display:"flex", 
            flexDirection:"row", 
            justifyContent:"center",
            border: 0, 
            borderRadius: '16px', 
            borderColor: '#000000', 
            padding:0, 
            height:'20px', 
            display:'flex', 
            justifyContent:'left', 
            alignItems:'center'
          }}>
            <Typography sx={{fontSize:12}}>
              {nftid==="any" ? 'Any NFT' : `ID #${nftid}`}
            </Typography>      
          </Box>
          <Box sx={{
            display:"flex", 
            flexDirection:"row", 
            justifyContent:"center",
            border: 0, 
            borderRadius: '16px', 
            borderColor: '#000000', 
            padding:0, 
            height:'25px', 
            display:'flex', 
            justifyContent:'left', 
            alignItems:'center'
          }}>
            <Stack noWrap direction="row" sx={{mt:'-5px'}}>
              <Typography variant='h6' align="left" sx={{fontSize:10, color:'#878787'}}>
                {currentContractData.floor_price? 'Ξ10.62' : 'Ξ10.62'}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Card>
    );
  }
};

export default ContractCard;