import * as React from "react";
import axios from 'axios';
import Modal from "@mui/material/Modal";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ethers } from 'ethers'
import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'

import { Contract, BrowserProvider, JsonRpcProvider, Interface } from 'ethers';
//import { Multicall } from 'ethereum-multicall';

import {Skeleton } from '@mui/material';
import {getAllERC721Transactions} from './MoralisFuncs';
import {Typography,Box,Button} from "@mui/material";

import { useConnectWallet } from '@web3-onboard/react'
import { multicall3Abi } from "viem";
import { useNFTData } from './NFTDataContext';

const loadingStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  maxHeight: "500px", 
  outline: "none",
};


const style = {
  maxHeight: "500px", 
  overflowY:"scroll"
};


const outerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  maxHeight: "500px", 
  outline: "none",
};



export function ContractModal(props) {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { nftData, setNftData } = useNFTData();

  const [listOfContracts, setListOfContracts] = React.useState([{'contract':'0', 'symbol':'0'}]);
  const userAccount = props.userAccount;
  const setBAYC = props.setBAYC;
  const NFTsetOpen = props.NFTsetOpen;
  const switchPC = props.switchPC;

  // Debug log props
  React.useEffect(() => {
    console.log("ContractModal Props:", {
      userAccount,
      setBAYC: !!setBAYC,
      NFTsetOpen: !!NFTsetOpen,
      switchPC
    });
  }, [userAccount, setBAYC, NFTsetOpen, switchPC]);

  // Debug log NFT data
  React.useEffect(() => {
    console.log("NFTData Context State:", nftData);
  }, [nftData]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingText, setLoadingText] = React.useState("Loading ERC721 contracts");
  const [animation, setAnimation] = React.useState("wave");

  async function onSelectCntrct(cntrct) {
    console.log("onSelectCntrct called with:", cntrct);
    
    // Ensure the contract address is valid
    if (!ethers.isAddress(cntrct)) {
      console.error("Invalid contract address:", cntrct);
      return;
    }

    // Call setBAYC with the validated contract address
    if (setBAYC) {
      console.log("Calling setBAYC with:", cntrct);
      setBAYC(cntrct);
    } else {
      console.warn("setBAYC function not provided to ContractModal");
    }

    // Close the modal
    handleClose();
    
    // Only proceed with NFT selection for Call options
    if (switchPC === 'Call' && NFTsetOpen) {
      console.log("Attempting to open NFT selection modal", {
        switchPC,
        hasNFTData: !!(nftData && nftData[cntrct])
      });
      
      setTimeout(() => {
        if (nftData && nftData[cntrct]) {
          NFTsetOpen(true);
        }
      }, 100);
    }
  }

  const listContracts = async () => {
    console.log("listContracts called. Current state:", {
      userAccount,
      hasNFTData: !!nftData
    });

    try {
      setAnimation("wave");
      let contractsData = nftData;
      
      if (!contractsData) {
        console.log("No cached NFT data, fetching from blockchain...");
        const ll = await getAllERC721Transactions(userAccount);
        console.log("Fetched NFT data:", ll);
        setNftData(ll);
        contractsData = ll;
      }

      if (contractsData && Object.keys(contractsData).length > 0) {
        console.log("Processing contract data:", contractsData);
        
        const tempList = Object.entries(contractsData).map(([address, tokens]) => {
          const token = tokens[0];
          console.log("Processing token data for address", address, ":", token);
          
          return {
            'contract': address,
            'symbol': token.symbol || token.name || 'Unknown Collection',
            'name': token.name || token.symbol || 'Unknown Collection',
            'image': token.image || null
          };
        });
        
        console.log("Final contract list:", tempList);
        setListOfContracts(tempList);
      } else {
        console.log("No contract data found");
        setListOfContracts([{'contract':'0', 'symbol':'0', 'name': 'Unknown Collection'}]);
        setLoadingText("No ERC721 NFTs found for wallet");
      }
    } catch (error) {
      console.error("Error in listContracts:", error);
      setLoadingText(`Error: ${error.message}`);
    } finally {
      setAnimation(false);
    }
  };

  // Log when listOfContracts changes
  React.useEffect(() => {
    console.log("listOfContracts updated:", listOfContracts);
  }, [listOfContracts]);

  if (listOfContracts[0].contract == '0'){
    return(
      <div>
        <Button variant='outlined' color={switchPC == 'Call' ? 'primary': 'secondary'} 
                onClick={ () => {handleOpen(); listContracts()}}
                disabled={!ethers.isAddress(userAccount)}
                > 
          {ethers.isAddress(userAccount) ? 'Select' : 'Connect wallet'} 
        </Button>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={loadingStyle}>
              <Typography> {loadingText} </Typography>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <div>
                      <ListItem alignItems="flex-start">
                        <Skeleton animation={animation} variant="rectangular" width='100%' 
                        sx={{marginTop:1, borderRadius:2, bgcolor: animation == "wave" ? "":"secondary.main" }}/>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                  </div>
              </List>
          </Box>
        </Modal>
      </div> 
    );
  } else {
    return (
      <div>
        <Button variant='outlined' color={switchPC == 'Call' ? 'primary': 'secondary'}
                onClick={ () => {handleOpen(); listContracts()}}
                disabled={!ethers.isAddress(userAccount)}
                > 
          {ethers.isAddress(userAccount) ? 'Select' : 'Connect wallet'} 
        </Button>
        <Modal open={open} 
               onClose={handleClose} 
               aria-labelledby="modal-modal-title" 
               aria-describedby="modal-modal-description">
        <Box sx={outerStyle}>
        <Typography> SELECT CONTRACT </Typography>
        <Box sx={style}>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {listOfContracts.map((step, index) => (
                  <div key={index}>
                      <ListItem alignItems="flex-start" >
                          <ListItemAvatar>
                          <Avatar alt={step.symbol} src="/static/images/avatar/1.jpg" />
                          </ListItemAvatar>
                          <ListItemText
                            primary={step.name || step.symbol || 'Unknown Collection'}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {step.contract.substring(0,7) + '...' + step.contract.substring(26,32)}
                                </Typography>
                              </React.Fragment>
                            }
                            />
                          <Button variant='outlined' color={switchPC == 'Call' ? 'primary': 'secondary'} sx={{marginTop: 1}} onClick={() => {onSelectCntrct(step.contract)}}>
                              Select
                          </Button>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                  </div>))}
              </List>
          </Box>
        </Box>

        </Modal>
      </div>
    );
  }
}