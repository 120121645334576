import React, { createContext, useContext, useState } from 'react';

const NFTDataContext = createContext();

export function NFTDataProvider({ children }) {
  const [nftData, setNftData] = useState(null);
  
  return (
    <NFTDataContext.Provider value={{ nftData, setNftData }}>
      {children}
    </NFTDataContext.Provider>
  );
}

export function useNFTData() {
  const context = useContext(NFTDataContext);
  if (context === undefined) {
    throw new Error('useNFTData must be used within a NFTDataProvider');
  }
  return context;
} 