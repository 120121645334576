import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import OrderCard from './OrderCard';

const AnimatedOrderCard = ({ theme, order, isBid, userAccount, curr, option, openModalId, setOpenModalId, handleClose,
  contractAddress, strike, expiry, isCall,
  modalSelectNFTVisible,
  setModalSelectNFTVisible,
  modalSelectedNFT, 
  setModalSelectedNFT,
  mode,
  toggleTabBar,
  blockMapping,
  userActiveBids,
  wallet
}) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (order && order.price) {
      setAnimate(true);
      const timer = setTimeout(() => setAnimate(false), 1000); // 1 second animation
      return () => clearTimeout(timer);
    }
  }, [order?.price]);

  return (
    <Box
      sx={{
        transition: 'background-color 0.5s ease',
        backgroundColor: animate ? 'lightgreen' : 'transparent',
        minWidth:'100%',
        maxWidth:'100%'
      }}
    >
      <OrderCard theme={theme} order={order} isBid={isBid} userAccount={userAccount} curr={curr}  option={option}
      openModalId={openModalId} // Pass down state
      setOpenModalId={setOpenModalId} 
      handleClose={handleClose} 
      contractAddress={contractAddress}
      strike={strike}
      expiry={expiry}
      isCall = {isCall}
      modalSelectNFTVisible={modalSelectNFTVisible}
      setModalSelectNFTVisible={setModalSelectNFTVisible}
      modalSelectedNFT={modalSelectedNFT}
      setModalSelectedNFT={setModalSelectedNFT}
      mode={mode}
      toggleTabBar={toggleTabBar}
      blockMapping={blockMapping}
      userActiveBids={userActiveBids}
      wallet={wallet}
      />
    </Box>
  );
};

export default AnimatedOrderCard;