import React, { useState } from 'react';
import { Card, CardContent, Stack, ButtonGroup, Button, Typography, Grid, Alert, TextField, Box } from '@mui/material';
import FormattedPrice from './FormattedPrice';
import ModalCreateOrder from './ModalCreateOrder';
import { ethers } from 'ethers'
import { SelectNFT } from './OrderViewSelectNFT';
import { acceptOrder, matchOrders } from './ethCreateOffer';
import GreeksCard from './greeksCard';
import { convertBlocksToTime } from './Etherfuncs';

const OrderCard = ({ theme, order, isBid, userAccount, curr, option, openModalId, setOpenModalId, handleClose,
  contractAddress,
  strike,
  expiry,
  isCall,
  modalSelectNFTVisible,
  setModalSelectNFTVisible,
  modalSelectedNFT, 
  setModalSelectedNFT,
  mode,
  toggleTabBar,
  blockMapping,
  userActiveBids,
  wallet
 }) => {
    function updateBlockMapping(blockMapping, expiry, bb) {
      const expiryKey = String(expiry);
      if (!blockMapping.hasOwnProperty(expiryKey)) {
        const blocks = expiry - bb;
        const time = convertBlocksToTime(blocks);
        const newEntry = { blocks, time };
      
        let obj =  {
          ...blockMapping,
          [expiryKey]: newEntry,
        }
        blockMapping = Object.keys(obj).sort().reduce((acc, key) => (acc[key] = obj[key], acc), {});
      }
      return blockMapping;
    }

  const newBlockMapping = updateBlockMapping(blockMapping, expiry, curr);

  const orderId = order?._orderId;


  //console.log("test567 OrderCard render - orderId:", orderId);
  //console.log("test567 OrderCard render - modalSelectedNFT:", modalSelectedNFT);

  const cardStyle = {
    backgroundImage: theme.custom.gradient1,
    margin: 2,
    minHeight: '20px',
    border: 0,
    borderColor: isBid ? 'primary.main' : 'secondary.main',
    width: '100%',
    padding:0
  };

  const buttonGroupStyle = {
    marginBottom: 0,
    marginTop: 0,
    width: '45%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  };

  const findMatchingBidOrder = (specificOptionId, criteria) => {
    if (!userActiveBids) return null;
  
    console.log("findMatchingBidOrder: Searching for matching bid with optionId:", specificOptionId);
    console.log("findMatchingBidOrder: User active bids:", userActiveBids);
    
    // Find all matching bids
    const matchingBids = userActiveBids.filter(bid => {
      const criteriaMatches = 
        Number(bid.strike) === Number(criteria.strike) &&
        Number(bid.expiry) === Number(criteria.expiry) &&
        bid.isCall === criteria.isCall &&
        String(bid.underlyingContract).toLowerCase() === String(criteria.contractAddress).toLowerCase();
      
      if (!criteriaMatches) return false;
  
      // For specific option offers
      if (!bid.isGeneral && specificOptionId) {
        return Number(bid.optionId) === Number(specificOptionId);
      }
      
      return true;
    });
  
    // Sort by price in descending order and return the highest bid's orderId
    return matchingBids.length > 0 
      ? matchingBids.sort((a, b) => Number(b.price) - Number(a.price))[0]._orderId 
      : null;
  };

// Update the handleBuyClick function
const handleBuyClick = () => {
  console.log("handleBuyClick: Starting buy process");
  console.log("handleBuyClick: Order details:", order);
  
  if (order) {
      const matchingBidOrderId = findMatchingBidOrder(order.optionId, {
          strike,
          expiry,
          isCall,
          contractAddress
      });
      
      if (matchingBidOrderId) {
          console.log("handleBuyClick: Found matching bid, matching orders", {
              bidId: matchingBidOrderId,
              offerId: orderId
          });
          matchOrders(matchingBidOrderId, orderId);
      } else {
          console.log("handleBuyClick: No matching bid found, accepting offer directly");
          acceptOrder(orderId);
      }
  }
};

  const [extensionError, setExtensionError] = useState('');
  const [extensionBlocks, setExtensionBlocks] = useState('');

  const validateExtension = (blocks) => {
    if (!blocks || isNaN(blocks)) {
      setExtensionError('Please enter a valid number of blocks');
      return false;
    }

    const newValidUntil = Number(curr) + Number(blocks);
    
    if (newValidUntil <= order.validUntil) {
      setExtensionError('New expiry must be later than current expiry');
      return false;
    }

    if (newValidUntil >= (expiry - 2000)) {
      setExtensionError('New expiry must be at least 2000 blocks before option expiry');
      return false;
    }

    setExtensionError('');
    return true;
  };


  React.useEffect(() => {
    if (openModalId === `${String(strike)}${String(expiry)}${isCall? 'Call':'Put'}${isBid ? 'Bid' : 'Offer'}`) {
      // Force a re-render when the modal should open
      console.log("test567 Force update due to modal open");
      forceUpdate();
    }
  }, [openModalId]);
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  const handleModalOpen = React.useCallback(() => {
    const modalId = `${String(strike)}${String(expiry)}${isCall? 'Call':'Put'}${isBid ? 'Bid' : 'Offer'}`;
    setOpenModalId(modalId);
  }, [strike, expiry, isCall, isBid]);
  

  return (
    <>
      <Grid item xs={12}>
        <Card style={cardStyle}>
          <CardContent sx={{ 
            padding: '0 !important', 
            paddingBottom: '0 !important',
            paddingTop: '0 !important',
            paddingLeft: isBid ? '0 !important' : '0.25rem !important',
            paddingRight: isBid ? '0.25rem !important' : '0 !important',
            margin: 0, 
            height: '100%' 
          }}>
            <Stack direction="row" sx={{ 
               minHeight: '100%', padding: 0, margin: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' 
            }}>
              {isBid && (
                <ButtonGroup orientation="vertical" sx={buttonGroupStyle}>
                   <Button 
                    variant="contained" 
                    size="small" 
                    sx={{ 
                        fontSize: 10, paddingTop: 0, paddingBottom: 0, flex: 1, color: 'white' 
                    }} 
                    color='secondary'
                    disabled={!order ||!userAccount || (String(order.orderCreator).toLowerCase() === String(userAccount).toLowerCase())}
                    onClick={() => {
                      if (order) {
                          if (order.isGeneral) {
                              //console.log("test567 Button clicked, current modalSelectedNFT:", modalSelectedNFT);
                              if (modalSelectedNFT[orderId]?.optionId) {
                                  //console.log("test567 Accepting order with optionId:", modalSelectedNFT[orderId].optionId);
                                  if (modalSelectedNFT[orderId].isListed) {
                                      // Call matchOrders with both order IDs
                                      //console.log("MATCH!!!", orderId, modalSelectedNFT[orderId].listingOrderId, modalSelectedNFT[orderId])
                                      matchOrders(orderId, modalSelectedNFT[orderId].listingOrderId);
                                  } else {
                                      // Call acceptOrder for held options
                                      acceptOrder(orderId, modalSelectedNFT[orderId].optionId);
                                  }
                              } else {
                                  //console.log("test567 Opening modal for orderId:", orderId);
                                  setModalSelectNFTVisible(orderId);
                              }
                          } else {
                              //console.log("test567 Non-general order, accepting directly");
                              acceptOrder(orderId);
                          }
                      }
                  }}
                >
                    Sell {modalSelectedNFT[orderId]?.optionId ? `#${modalSelectedNFT[orderId].optionId}` : ''}
                </Button>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    disabled={!userAccount}
                    sx={{ 
                      fontSize: 10, 
                    paddingTop: 0, 
                    paddingBottom: 0, 
                    flex: 1, 
                    color: theme.palette.mode === 'dark' ? 'white' : 'black' 
                    }} 
                    color="secondary"
                    onClick={handleModalOpen}
                  >
                    Bid
                  </Button>
                </ButtonGroup>
              )}
              
              {order ? (
                <FormattedPrice 
                  price={order.price} 
                  isBid={isBid} 
                  myOrder={String(order.orderCreator).toLowerCase() === String(userAccount).toLowerCase()}
                  isSpecific={!order.isGeneral}
                  specificOptionId={order.optionId}
                />
              ) : (
                <FormattedPrice 
                  price={"-"} 
                  isBid={isBid} 
                  myOrder={true}
                  isSpecific={false}
                  specificOptionId={null}
                />
              )}
              
              {!isBid && (
                <ButtonGroup orientation="vertical" sx={buttonGroupStyle}>
                  <Button 
                    variant="contained" 
                    disabled={!order || !userAccount || (String(order.orderCreator).toLowerCase() === String(userAccount).toLowerCase())}
                    size="small" 
                    sx={{ 
                      fontSize: 10, paddingTop: 0, paddingBottom: 0, flex: 1, borderColor: 'white'
                    }} 
                    color='primary'
                    onClick={handleBuyClick}
                  >
                    Buy
                  </Button>
                  <Button 
                    variant="outlined" 
                    disabled={!userAccount}
                    size="small" 
                    sx={{ 
                      fontSize: 10, paddingTop: 0, paddingBottom: 0, flex: 1, color: theme.palette.mode === 'dark' ? 'white' : 'black'
                    }} 
                    color="primary"
                    onClick={handleModalOpen}
                  >
                    Ask
                  </Button>
                </ButtonGroup>
              )}
            </Stack>
            
          </CardContent>
        </Card>
        {false &&
              <GreeksCard
              theme={theme}
              mode={mode}
              contractAddress={contractAddress}
              price={order && order.price ? Number(ethers.formatEther(String(order.price))) : 0}
              isCall={isCall}
              strike={strike}
              expiry={expiry}
              block={curr}
              showColor={false}
          />}
      </Grid>
      {/* ModalCreateOrder component 
      Bid should be general by default
      Offer is always specific
      TODO: Be able to choose general or specific for bids
      */}

      <ModalCreateOrder
        key={`modal-${openModalId}-${userAccount}`}
        activeStrike={strike}
        activeExpiry={expiry}
        underlyingContract={contractAddress}
        activePC={isCall ? 'call' : 'put'}
        bb={curr}
        switchPC={isCall ? 'Call' : 'Put'}
        isSpecific={!isBid}
        activeUnderlyingID={null}
        optionID={null}
        orderType={isBid ? 'Bid' : 'Offer'}
        openT={openModalId === `${String(strike)}${String(expiry)}${isCall? 'Call':'Put'}${isBid ? 'Bid' : 'Offer'}`}
        setOpenT={handleClose}
        expIsBlock={true}
        blockMapping={newBlockMapping}
        userAccount={userAccount}
        wallet={wallet}
      />

{(order && userAccount && isBid) && (
    <SelectNFT
        open={modalSelectNFTVisible === orderId}  // Simplified condition
        setOpen={setModalSelectNFTVisible}
        setModalSelectedNFT={(newSelection) => {
            console.log("test567 Setting modalSelectedNFT in OrderCard:", newSelection);
            setModalSelectedNFT(newSelection);
        }}
        modalSelectedNFT={modalSelectedNFT}
        userAccount={userAccount}
        filterContract={contractAddress}
        filterIsCall={isCall}
        filterStrike={strike}
        filterExpiry={expiry}
        orderId={orderId}
        theme={theme}
        mode={mode}
        toggleTabBar={toggleTabBar}
    />
)}

    </>
  );
};

export default OrderCard;