import React, { createContext, useContext, useState, useEffect } from 'react';
import { getBlockGas, supabase } from '../supaBaseFuncs';

const BlockContext = createContext();

export function useBlockNumber() {
  const context = useContext(BlockContext);
  if (context === undefined) {
    throw new Error('useBlockNumber must be used within a BlockProvider');
  }
  return context;
}

export function BlockProvider({ children }) {
  const [blockData, setBlockData] = useState({
    blockNumber: 0,
    gasPrice: '0',
    lastUpdate: Date.now(),
    loading: true,
    error: null
  });

  const updateBlockData = async (force = false) => {
    try {
      const now = Date.now();
      if (force || now - blockData.lastUpdate >= 10000) {
        const [blockNumber, gasPrice] = await getBlockGas();
        setBlockData(prev => ({
          ...prev,
          blockNumber,
          gasPrice,
          lastUpdate: now,
          loading: false,
          error: null
        }));
      }
    } catch (error) {
      console.error('Failed to fetch block data:', error);
      setBlockData(prev => ({
        ...prev,
        error: error.message,
        loading: false
      }));
    }
  };

  // Force refresh method
  const forceRefresh = () => updateBlockData(true);

  useEffect(() => {
    updateBlockData(true);

    const channel = supabase.channel('block-updates')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'blockInfo' },
        () => updateBlockData()
      )
      .subscribe();

    const interval = setInterval(() => updateBlockData(), 30000);

    return () => {
      channel.unsubscribe();
      clearInterval(interval);
    };
  }, []);

  const value = {
    ...blockData,
    forceRefresh,
    getBlockDifference: (block1, block2) => block1 - block2,
    formatBlockNumber: (blockNumber) => blockNumber.toLocaleString()
  };

  return (
    <BlockContext.Provider value={value}>
      {children}
    </BlockContext.Provider>
  );
} 