import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { AmpStoriesOutlined } from "@material-ui/icons";

const MORALIS_API_KEY = process.env.REACT_APP_MORALIS_API_KEY;

// Initialize Moralis function
async function initializeMoralis() {
    if (!Moralis.Core.isStarted) {
        await Moralis.start({
            apiKey: MORALIS_API_KEY,
        });
    }
}

async function getTransactions(userAddress, nftAddress) {
    try {
        await initializeMoralis();
        const chain = EvmChain.SEPOLIA;
        
        const response = await Moralis.EvmApi.nft.getWalletNFTs({
            address: userAddress,
            chain,
            tokenAddresses: [nftAddress],
        });

        const newList = response.result.map(nft => ({
            id: nft.tokenId
        }));

        console.log("newlist - newlist", newList);
        return newList;
    } catch (error) {
        console.error("Error fetching NFTs:", error);
        return [];
    }
}

async function getAllERC721Transactions(userAddress) {
    try {
        await initializeMoralis();
        const chain = EvmChain.SEPOLIA;
        
        const response = await Moralis.EvmApi.nft.getWalletNFTs({
            address: userAddress,
            chain,
        });
        //console.log("getAllERC721Transactions - response", response.result);
        // Group NFTs by contract address
        const newList = response.result.reduce((acc, nft) => {
            const contractAddress = nft.tokenAddress._value;
            if (!acc[contractAddress]) {
                acc[contractAddress] = [];
            }
            acc[contractAddress].push({
                id: nft.tokenId,
                name: nft.name,
                symbol: nft.symbol,
                tokenAddress: nft.tokenAddress._value,
                amount: nft.amount,
                chain: nft.chain,
                contractType: nft.contractType,
                tokenUri: nft.tokenUri,
                metadata: nft.metadata,
            });
            return acc;
        }, {});

        console.log("getAllERC721Transactions - newList", newList);
        return newList;
    } catch (error) {
        console.error("Error fetching all NFTs:", error);
        return [];
    }
}

export { getTransactions, getAllERC721Transactions };